import React, { useState } from 'react'
import './Login.css'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export const loginSchema = () => {
    let [passwordShown, setPasswordShown] = useState(false)
    const { t } = useTranslation()

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true)
    }

    return {
        initialValues: {
            email: localStorage.email ? localStorage.email : '',
            google_id: '',
            password: localStorage.password ? localStorage.password : '',
            remember_me: localStorage.remember_me ? localStorage.remember_me : false,
        },
        validationArray: Yup.object({
            email: Yup.string().email(t('Invalid email format')).required(t('Required!')),
            google_id: Yup.string(),
            password: Yup.string().when(['google_id'], {
                is: (googleID) => !googleID,
                then: Yup.string().required(t('Required!')),
            }),
        }),
        login: {
            formData: [
                {
                    label: t('email_address'),
                    name: 'email',
                    type: 'email',
                    placeholder: t('enter_your_email_address'),
                    class: 'col-12',
                    autoFocus: true,
                },
                {
                    label: t('password'),
                    name: 'password',
                    type: passwordShown ? 'text' : 'password',
                    placeholder: t('enter_your_password'),
                    class: 'col-12',
                    endAdornment: passwordShown ? (
                        <span
                            className="material-icons cursorPointer"
                            onClick={togglePasswordVisiblity}
                        >
                            visibility_off
                        </span>
                    ) : (
                        <span
                            className="material-icons cursorPointer"
                            onClick={togglePasswordVisiblity}
                        >
                            visibility
                        </span>
                    ),
                },
            ],
        },
        rememberMe: false,
    }
}
