import React, { useReducer } from 'react'
import MembershipContext from './membershipContext'
import MembershipReducer from './membershipReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { RESPONSE_STATUS, CLEAR_RESPONSE } from './membershipTypes'

const MembershipState = (props) => {
    const initialState = {
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(MembershipReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const freeTrialActivation = async (formData) => {
        const from = 'freeTrialActivation'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'membership')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <MembershipContext.Provider
            value={{
                responseStatus: state.responseStatus,
                freeTrialActivation,
                clearResponse,
            }}
        >
            {props.children}
        </MembershipContext.Provider>
    )
}

export default MembershipState
