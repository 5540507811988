import React, { useContext } from 'react'
import InitialFunction from '../product/views/InitialFunction'
import { Route, Redirect } from 'react-router-dom'
import AuthContext from '../product/context/auth/authContext'
function PrivateRoute({ component: Component, ...rest }) {
    const authContext = useContext(AuthContext)
    const { token } = authContext
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.token ? (
                    <>
                        <Component {...props} />
                    </>
                ) : (
                    <Redirect to={{ pathname: '/', state: { referer: props.location } }} />
                )
            }
        />
    )
}

export default PrivateRoute
