import React, { useEffect } from 'react'
import { mapData, converDate } from '../../../../common/components'
import PrimaryButton from '../../../atoms/PrimaryButton'
import { useFormik } from 'formik'
import Popup from '../../../../../custom/components/organisms/Popup'
import CustomAlert from '../../../atoms/CustomAlert'
import SecondaryButton from '../../../atoms/SecondaryButton'

const PaymentComponent = (props) => {
    const formik = useFormik({
        initialValues: props.initialValues,
        validationSchema: props.validationSchema,
        onSubmit: (values) => {
            console.log('SubmitValues', values)
            if (props.setIsLoading) {
                props.setIsLoading(true)
            }
            props.ActionFunction(values)
            // createStripeCard({
            //     ...values,
            // })
        },
    })

    const paymentForm = props.formFields ? { formik: formik, ...props.formFields } : {}

    useEffect(() => {
        if (props.userStripeId) formik.values.customer_id = props.userStripeId
    }, [props.userStripeId])

    useEffect(() => {
        if (props.additionalfieldUpdate) {
            props.additionalfieldUpdate(formik.values)
        }
    }, [formik.values])

    if (props.data) {
        useEffect(() => {
            if (props.data.status === 'update') {
                formik.values.source_id = props.data.data.id
                formik.values.customer_id = props.data.data.customer
                // Update For Card
                if (props.from === 'card' || props.from === 'custom_card') {
                    formik.values.exp_month = ('0' + props.data.data.exp_month).slice(-2)
                    formik.values.exp_year = props.data.data.exp_year
                }
                //Update For Bank
                if (props.from === 'bank' || props.from === 'custom_bank') {
                    formik.values.source_id = props.data.data.id
                    formik.values.customer_id = props.data.data.customer
                    formik.values.account_holder_name = props.data.data.account_holder_name
                    formik.values.account_holder_type = props.data.data.account_holder_type
                }
            } else if (props.data.status === 'verify') {
                formik.values.source_id = props.data.data.id
                formik.values.customer_id = props.data.data.customer
            }
        }, [props.data])
    }
    useEffect(() => {
        if (props.additionalfieldUpdate) {
            props.additionalfieldUpdate(formik.values)
        }
    }, [formik.values])

    return (
        <Popup
            open={props.data.popup}
            handleClose={() => props.function(false, '', null)}
            size="md"
            modaltitle={props.HeadText ? props.HeadText : ''}
        >
            <div className="cardDetails">
                <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                    {props.CustomAlert ? (
                        <CustomAlert
                            type={props.CustomAlert.type}
                            icon={props.CustomAlert.icon}
                            label={props.CustomAlert.label}
                        />
                    ) : null}
                    <div className="row">
                        {mapData(paymentForm)}
                        <div className="col-12 text-right paymentModalAct">
                            {props.BackBtnLabel ? (
                                <SecondaryButton
                                    id="payment_backBtn"
                                    btnSize="large"
                                    label={props.BackBtnLabel}
                                    onClick={() => props.function(false, '', null)}
                                    disabled={props.isLoading ? props.isLoading : false}
                                />
                            ) : null}
                            {props.SubmitBtnLabel ? (
                                <PrimaryButton
                                    id="payment_checkout-submit"
                                    disabled={props.isLoading ? props.isLoading : false}
                                    // onSubmit={(e) => {
                                    //     handleNextClose(e)
                                    // }}
                                    btnSize="large"
                                    type="submit"
                                    label={props.SubmitBtnLabel}
                                />
                            ) : null}
                        </div>
                    </div>
                </form>
            </div>
        </Popup>
    )
}

export default PaymentComponent
