import React from 'react'
import { Link } from 'react-router-dom'
import './RegistrationLayout.css'

import { LOGO, SITE_NAME } from '@/utils'
import { useTranslation } from 'react-i18next'
const RegistrationLayout = (props) => {
    const { t } = useTranslation()
    return (
        <section className="registrationCnt">
            <div className="registration">
                <div className="plBox">
                    {/* <div className="text-center container regInfo">
                        <h2>Welcome to {SITE_NAME}</h2>
                        <h4>{'Create your free account'}</h4>
                    </div> */}
                    <div className="ModalLogoSec">
                        <img className="Logo" src="/assets/images/logo.png" alt="Images" />
                        <h4 className="Tit">{t('quick_signup')}</h4>
                        <h6 className="subTit">{t('create_your_free_account_today.')}</h6>
                    </div>
                    {props.children}
                </div>
            </div>
        </section>
    )
}

export default RegistrationLayout
