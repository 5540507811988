import React, { PureComponent } from 'react'
import './Subcription.css'
import TertiaryButton from '../../atoms/TertiaryButton'

const SubcriptionComponent = () => {
    return (
        <div className="subcriptionBody">
            <h4 className="tit">Subscription plans</h4>
            <p className="info">
                Unlock the full potential of our website with our subscription plans. Choose the one
                that suits you best and experience all the benefits!
            </p>
            <div className="subcriptionPlansTable">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Features</th>
                            <th>Number of Auctions</th>
                            <th>Number of Direct sales</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Launch offer</td>
                            <td>Initiation</td>
                            <td>
                                Iliamator
                                <span className="subcriptionBadge">Recommended</span>
                            </td>
                            <td>Amateur Pro</td>
                            <td>Professional</td>
                            <td>Professional Pro</td>
                        </tr>
                        <tr>
                            <td>01</td>
                            <td>03</td>
                            <td>06</td>
                            <td>13</td>
                            <td>17</td>
                            <td>23</td>
                        </tr>
                        <tr>
                            <td>01</td>
                            <td>03</td>
                            <td>06</td>
                            <td>20</td>
                            <td>40</td>
                            <td>60</td>
                        </tr>
                        <tr>
                            <td>0 € per month</td>
                            <td>30 € per month</td>
                            <td>60 € per month</td>
                            <td>120 € per month</td>
                            <td>150 € per month</td>
                            <td>200 € per month</td>
                        </tr>
                        <tr>
                            <td>
                                <TertiaryButton label="Subscribe" />
                            </td>
                            <td>
                                <TertiaryButton label="Subscribe" />
                            </td>
                            <td>
                                <TertiaryButton label="Subscribe" />
                            </td>
                            <td>
                                <TertiaryButton label="Subscribe" />
                            </td>
                            <td>
                                <TertiaryButton label="Subscribe" />
                            </td>
                            <td>
                                <TertiaryButton label="Subscribe" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SubcriptionComponent
