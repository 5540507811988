import React, { useContext, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import './index.css'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import CommonContext from '@/product/context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import ProductContext from '@/product/context/product/productContext'
import { useTranslation } from 'react-i18next'
import { currencyFormat, maskEmailFront, dateTimeFormatFunction } from '@/custom/common/components'
import AuthContext from '@/product/context/auth/authContext'
import { Button, IconButton } from '@material-ui/core'
import BuyerContext from '@/product/context/buyer/buyerContext'
import CustomDialog from '@/custom/components/organisms/Dialog'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'

const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const BidHistory = (props) => {
    const classes = useStyles()
    const [bidPopUp, setBidPopUp] = React.useState({
        popup: false,
        id: 0,
    })
    const authContext = useContext(AuthContext)
    const buyerContext = useContext(BuyerContext)
    const { user, isAuthenticated } = authContext
    const { bidAward, responseStatus } = buyerContext
    const { t } = useTranslation()
    let [viewProduct, setViewProduct] = useState([])
    let [awardBid, setAwardBid] = useState(false)
    const [toggleDialog, setToggleDialog] = useState(false)
    const [currentData, setCurrentData] = useState({})

    const commonContext = useContext(CommonContext)
    const productContext = useContext(ProductContext)
    const { bidHistoryValue, clearBidHistoryValue } = commonContext
    const { search_allbidhistory, getAllBidHistory } = productContext

    const [search, setSearch] = useState({
        limit: 10,
        page: 1,
        id: 0,
        additionalField: 'u.phone',
    })
    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    useEffect(() => {
        if (bidHistoryValue) {
            if (bidHistoryValue.award_bid) {
                setAwardBid(bidHistoryValue.award_bid)
            } else {
                setAwardBid(false)
            }
            setSearch({ ...search, id: bidHistoryValue?.id, page: 1 })
            setBidPopUp({ popup: true, id: bidHistoryValue })
            clearBidHistoryValue()
        }
    }, [bidHistoryValue])

    useEffect(() => {
        if (search.id !== 0) {
            let BidhistoryValues = {
                ...search,
                filters: {
                    user_id: {
                        value: user?.id,
                        type: 'in',
                        field: 'b.user_id',
                    },
                },
            }
            getAllBidHistory(BidhistoryValues)
        }
    }, [search])

    const onHandlePage = (event, value) => {
        setSearch({ ...search, page: value })
    }
    const onHandleAward = (data) => {
        bidAward({
            action: 'award',
            id: data.bidid,
            pid: data.project_id,
        })
    }

    useEffect(() => {
        if (responseStatus && responseStatus.from == 'bidAward') {
            setToggleDialog(false)
            setBidPopUp({ popup: false, id: 0 })
        }
    }, [responseStatus])

    useEffect(() => {
        setViewProduct(search_allbidhistory.records.length ? search_allbidhistory.records : [])
    }, [search_allbidhistory])

    return (
        <>
            <CustomDialog
                title={t('Award a bid')}
                className="alertDialog bidAwardCnfModal"
                open={toggleDialog}
                function={changeDialogStatus}
            >
                <h5>{t('Please confirm if you want to continue with this action')}</h5>
                {/* <h5>{t('please_confirm')}</h5> */}
                <div className="actionWrapper">
                    <Button id="cancel" onClick={() => setToggleDialog(false)}>
                        {t('cancel')}
                    </Button>
                    <PrimaryButton
                        onClick={() => onHandleAward(currentData)}
                        id="confirm_bid_submit"
                        type="button"
                        label={t('submit')}
                    />
                </div>
            </CustomDialog>
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={bidPopUp.popup}
                className={`${classes.modal} customModal`}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={bidPopUp.popup}>
                    <div className="bidHistoryModal">
                        <div className={classes.paper}>
                            <div className="modal fade show custom-modal">
                                <div className="modal-dialog modal-xl">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h4 className="modal-title">{t('bid_history')}</h4>
                                            <Button
                                                id="bidHistory_close"
                                                className="close"
                                                onClick={() => setBidPopUp({ popup: false, id: 0 })}
                                            >
                                                <span className="material-icons">close</span>
                                            </Button>
                                        </div>
                                        <div className="modal-body cm-body table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">{'ID'}</th>
                                                        <th scope="col">{t('Bidder name')}</th>
                                                        <th scope="col">{t('current_bid')}</th>
                                                        <th scope="col">{t('date')}</th>
                                                        <th scope="col">{t('status')}</th>
                                                        {user?.admin &&
                                                        user.as_employees_id ==
                                                            viewProduct[0]?.seller_id ? (
                                                            <th scope="col">{t('Phone number')}</th>
                                                        ) : null}
                                                        {awardBid ? (
                                                            <th scope="col">{t('Action')}</th>
                                                        ) : null}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {viewProduct.map((data, index) => {
                                                        if (data.user_id === user?.id) {
                                                            return (
                                                                <>
                                                                    <tr>
                                                                        <td>{data.user_id}</td>
                                                                        <td>
                                                                            {user?.admin
                                                                                ? data.first_name +
                                                                                  ' ' +
                                                                                  data.last_name
                                                                                : data.user_id ===
                                                                                  user?.user_id
                                                                                ? data.first_name +
                                                                                  ' ' +
                                                                                  data.last_name
                                                                                : maskEmailFront(
                                                                                      data.first_name +
                                                                                          ' ' +
                                                                                          data.last_name,
                                                                                  )}{' '}
                                                                        </td>
                                                                        <td>
                                                                            {data.declined === 1 ? (
                                                                                <del>
                                                                                    {currencyFormat(
                                                                                        data.proposed_amount,
                                                                                    )}
                                                                                </del>
                                                                            ) : (
                                                                                currencyFormat(
                                                                                    data.proposed_amount,
                                                                                )
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {dateTimeFormatFunction(
                                                                                data.created_at,
                                                                            )}
                                                                        </td>
                                                                        <td>
                                                                            {data.declined === 1
                                                                                ? t('Retracted')
                                                                                : t('Valid')}
                                                                        </td>
                                                                        {user?.admin &&
                                                                        user.as_employees_id ==
                                                                            viewProduct[0]
                                                                                ?.seller_id ? (
                                                                            <td>{data.phone}</td>
                                                                        ) : null}
                                                                        {awardBid ? (
                                                                            <td scope="col">
                                                                                {' '}
                                                                                <IconButton>
                                                                                    <span
                                                                                        onClick={() => {
                                                                                            setCurrentData(
                                                                                                data,
                                                                                            )
                                                                                            setToggleDialog(
                                                                                                true,
                                                                                            )
                                                                                        }}
                                                                                        className="material-icons"
                                                                                    >
                                                                                        emoji_events
                                                                                    </span>
                                                                                </IconButton>{' '}
                                                                            </td>
                                                                        ) : null}
                                                                    </tr>
                                                                </>
                                                            )
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                            <div
                                                className="d-flex justify-content-between align-items-center flex-wrap w-100 mb-3 pagination-wrapper"
                                                id="bidhistory_pagination"
                                            >
                                                <h6>
                                                    {t('showing')} {search_allbidhistory.setDisp}
                                                    {' of'} {search_allbidhistory.totalRecords}
                                                </h6>
                                                <Pagination
                                                    count={Math.ceil(
                                                        search_allbidhistory.totalRecords /
                                                            search.limit,
                                                    )}
                                                    page={search.page}
                                                    onChange={onHandlePage}
                                                    siblingCount={3}
                                                    showFirstButton
                                                    showLastButton
                                                    boundaryCount={2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    )
}

export default BidHistory
