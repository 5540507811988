import React, { useContext, useEffect } from 'react'
import './Login.css'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { useFormik } from 'formik'
import AuthContext from '@/product/context/auth/authContext'
import { handleRedirectInternal, mapData } from '@/product/common/components'
import { useTranslation } from 'react-i18next'
import { Button } from '@material-ui/core'
import { useGoogleLogin, GoogleLogin } from '@react-oauth/google'
import jwtDecode from 'jwt-decode'
import CustomCommonContext from '@/custom/context/common/commonContext'

const LoginComponent = (props) => {
    const { t } = useTranslation()
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { setSignupModal, setLoginModal } = customCommonContext

    const { login, responseStatus, clearResponse, isAuthenticated } = authContext

    useEffect(() => {
        if (isAuthenticated) {
            handleRedirectInternal(history, '')
            // setLoginModal(false)
            // setSignupModal(false)
        }
    }, [isAuthenticated])

    const formik = useFormik({
        initialValues: props.initialValues,
        validationSchema: props.validationArray,
        validateOnBlur: false,
        onSubmit: (values) => {
            if (values.remember_me) {
                localStorage.email = values.email
                localStorage.password = values.password
                localStorage.remember_me = values.remember_me
            } else {
                delete localStorage.email
                delete localStorage.password
                delete localStorage.remember_me
            }

            login(values)
        },
    })

    const rememberMe = {
        formik: formik,
        data: [
            {
                label: t('remember_me'),
                name: 'remember_me',
                type: 'checkbox',
                placeholder: 'remember_me',
                class: '',
            },
        ],
    }
    const loginInfo = {
        formik: formik,
        data: props.loginInfo,
    }

    // useEffect(() => {
    //     console.log('LOCAL VSTORAGE', localStorage)
    // })

    const responseGoogle = (response) => {
        const userDetails = jwtDecode(response.credential)
        formik.values.email = userDetails.email
        formik.values.google_id = userDetails.jti
        login(formik.values)
    }

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    if (props.onSuccess) {
                        props.onSuccess()
                    } else {
                        handleRedirectInternal(history, 'search')
                    }
                }
            }
        }
    }, [responseStatus])

    return (
        <>
            <div className="ModalLogoSec">
                <img className="Logo" src="/assets/images/logo.png" alt="Images" />
                <h4 className="Tit">{t('login')}</h4>
                <h6 className="subTit">{t('continue_login')}</h6>
            </div>
            <div className="regForm">
                {props.googleSignIn ? (
                    <GoogleLogin
                        onSuccess={(credentialResponse) => {
                            responseGoogle(credentialResponse)
                        }}
                        onError={() => {
                            console.log('Login Failed')
                        }}
                    />
                ) : null}
                <b className="text-center d-block mt-3">-OR-</b>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="row">{Object.values(mapData(loginInfo))}</div>
                <div className="d-flex loginActBox justify-content-between align-items-center remebrMe">
                    {props.rememberMe ? Object.values(mapData(rememberMe)) : ''}

                    <Button
                        onClick={props.forgetModalChange}
                        className="aPass"
                        id="forgot_password_link"
                    >
                        {t('forgot password')}?
                    </Button>
                </div>

                <PrimaryButton id="login_button" label={t('login')} type="submit" />
                <p className="forPass">
                    {t('have_an_account')}?
                    <Button onClick={props.registerModalChange} id="login_signup" className="aPass">
                        {t('sign_up')}
                    </Button>
                </p>
            </form>
        </>
    )
}

export default LoginComponent
