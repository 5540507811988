import { Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import React, { useState, useRef, useEffect, useContext } from 'react'
// import CustomInput from '../../atoms/Inputs/CustomInput'
// import PrimaryButton from '../../atoms/PrimaryButton'
import { currencyFormat } from '../../custom/common/components'
const CartItem = (props) => {
    function calculateBasePrice(totalPrice, vatRate = 21) {
        // Convert VAT rate percentage to decimal and add 1
        const vatMultiplier = 1 + vatRate / 100
        // Calculate base price by dividing total price by the VAT multiplier
        const basePrice = totalPrice / vatMultiplier
        return basePrice
    }
    const { t } = useTranslation()
    const [product, setProduct] = useState()

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    return (
        <>
            {product ? (
                <>
                    <div className="cartItem d-flex justify-content-between align-items-start">
                        <div className="itemInfo d-flex justify-content-start">
                            <div className="pclImg">
                                <img
                                    src={
                                        global.site_url +
                                        '/uploads/product/' +
                                        product.projects_avatar
                                    }
                                    alt={product.title}
                                />
                            </div>
                            <div className="listContent">
                                <h2 id="product_title" className="listProdTitle">
                                    {product.projects_title}
                                </h2>
                                {props.from == 'buynowcart' ||
                                props.from == 'buynowcheckout' ||
                                (props.from == 'invoice' && product.buynow_autype === 'buynow') ? (
                                    <>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>{t('amount')}:</span>
                                                <span>{currencyFormat(product.amount)}</span>
                                            </h5>
                                            <h5>
                                                <span>{t('quantity')}:</span>{' '}
                                                <span>{product.qty}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>{t('sub_total')}:</span>
                                                <span>
                                                    {' '}
                                                    {product?.custom_projects_iva_percent
                                                        ? currencyFormat(
                                                              calculateBasePrice(
                                                                  product.per_total,
                                                                  21,
                                                              ),
                                                          )
                                                        : currencyFormat(product.per_total)}
                                                </span>
                                            </h5>
                                            {props.from == 'invoice' &&
                                            product.buynow_autype === 'buynow' ? (
                                                <h5>
                                                    <span>{t('delivery_status')}:</span>
                                                    <span>
                                                        {product.delivered === 1
                                                            ? 'In Transit'
                                                            : product.delivered === 2
                                                            ? 'Ready for Pickup'
                                                            : product.delivered === 3
                                                            ? 'Completed'
                                                            : product.delivered === 4
                                                            ? 'Cancelled'
                                                            : 'Pending'}
                                                    </span>
                                                </h5>
                                            ) : null}
                                        </div>
                                        {/* <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>VAT({product.tax_percent}%):</span>
                                                <span>{currencyFormat(product.total_tax)}</span>
                                            </h5>
                                        </div> */}
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>{t('total')}:</span>
                                                <span>{currencyFormat(product.per_total)}</span>
                                            </h5>
                                        </div>
                                    </>
                                ) : props.from == 'cart' ||
                                  props.from == 'checkout' ||
                                  (props.from == 'invoice' &&
                                      product.buynow_autype === 'auction') ||
                                  product.buynow_autype === 'live' ? (
                                    <>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>{t('sub_total')}:</span>
                                                <span>
                                                    {' '}
                                                    {product?.custom_projects_iva_percent
                                                        ? currencyFormat(
                                                              calculateBasePrice(
                                                                  product.per_total,
                                                                  21,
                                                              ),
                                                          )
                                                        : currencyFormat(product.per_total)}
                                                </span>
                                            </h5>
                                            {/* <h5>
                                                <span>{t('delivery_status')}:</span>
                                                <span>
                                                    {product.delivered === 1
                                                        ? 'In Transit'
                                                        : product.delivered === 2
                                                        ? 'Ready for Pickup'
                                                        : product.delivered === 3
                                                        ? 'Completed'
                                                        : product.delivered === 4
                                                        ? 'Cancelled'
                                                        : 'Pending'}
                                                </span>
                                            </h5> */}
                                        </div>
                                        {/* <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>VAT ({product.tax_percent}%):</span>
                                                <span>{currencyFormat(product.total_tax)}</span>
                                            </h5>
                                        </div> */}
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>{t('total')}:</span>
                                                <span>{currencyFormat(product.per_total)}</span>
                                            </h5>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>

                        <div className="listActBtn"></div>
                    </div>
                </>
            ) : null}
        </>
    )
}
export default CartItem
