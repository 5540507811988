//LOGO
export const LOGO = '/assets/images/logo.png'

export const BRAND_LOGO = '/assets/svg/logo.svg'

//SITE NAME
export const SITE_NAME = 'Subastas.pro'

// SIDE PANEL LIST
export const SIDE_PANEL = [
    {
        label: 'Dashboard',
        path: '/dashboard',
        type: 'single',
    },

    {
        label: 'Auctions',
        path: 'auction',
        type: 'multiple',
        subLinks: [
            {
                label: 'Active',
                path: '/auction/open',
            },
            {
                label: 'Upcoming',
                path: '/auction/upcomming',
            },
            {
                label: 'Draft',
                path: '/auction/draft',
            },
            {
                label: 'Past',
                path: '/auction/closed',
            },
        ],
    },
    {
        label: 'Video Auction',
        path: 'videoAuction',
        type: 'multiple',
        subLinks: [
            {
                label: 'Broadcast',
                path: '/videoAuction/broadcast',
            },
            {
                label: 'Live Auctions',
                path: '/videoAuction/live_auction',
            },
        ],
    },
    {
        label: 'Listing',
        type: 'multiple',
        path: 'listing',
        subLinks: [
            {
                label: 'Active',
                path: '/listing/open',
            },
            {
                label: 'Sold',
                path: '/listing/sold',
            },
            {
                label: 'Unsold',
                path: '/listing/unsold',
            },
            {
                label: 'Draft',
                path: '/listing/draft',
            },
            {
                label: 'Relisted',
                path: '/listing/relisted',
            },
        ],
    },
    {
        label: 'Template',
        type: 'multiple',
        path: 'templates',
        subLinks: [
            {
                label: 'Listing',
                path: '/templates/listing',
            },
            {
                label: 'Auction',
                path: '/templates/auction',
            },
        ],
    },
    {
        label: 'ERP',
        path: '/erp',
        type: 'pseudoMultiple',
        subLinks: [
            {
                label: 'Draft Auctions ',
                path: '/erp/auction/draft',
            },
            {
                label: 'Active Auctions',
                path: '/erp/auction/open',
            },
            {
                label: 'Closed Auctions',
                path: '/erp/auction/closed',
            },
            {
                label: 'All Auctions',
                path: '/erp/auction/all',
            },
        ],
    },
    {
        label: 'User',
        path: 'users',
        type: 'multiple',
        subLinks: [
            {
                label: 'All ',
                path: '/users/all',
            },
            {
                label: 'Active',
                path: '/users/active',
            },
            {
                label: 'Unverified',
                path: '/users/unverified',
            },
            {
                label: 'Deactivated',
                path: '/users/deactivate',
            },
            {
                label: 'Rejected',
                path: '/users/rejected',
            },
        ],
    },
    {
        label: 'Seller',
        path: 'org',
        type: 'multiple',
        subLinks: [
            {
                label: 'All ',
                path: '/org/all',
            },
            {
                label: 'Active',
                path: '/org/active',
            },
            {
                label: 'Deactivate',
                path: '/org/deactivate',
            },
            {
                label: 'Rejected',
                path: '/org/rejected',
            },
        ],
    },
    {
        label: 'Employee',
        path: 'employees',
        type: 'multiple',
        subLinks: [
            {
                label: 'All',
                path: '/employees/all',
            },
        ],
    },
    {
        label: 'Invoice',
        path: 'invoice',
        type: 'multiple',
        subLinks: [
            {
                label: 'All',
                path: '/invoice/all',
            },
            {
                label: 'Unpaid',
                path: '/invoice/unpaid',
            },
            {
                label: 'Paid',
                path: '/invoice/paid',
            },
            {
                label: 'Cancel',
                path: '/invoice/cancel',
            },
        ],
    },
    {
        label: 'Transactions',
        path: 'transaction',
        type: 'multiple',
        subLinks: [
            {
                label: 'Payment',
                path: '/transaction/payment',
            },
        ],
    },
    {
        label: 'Return',
        path: 'return',
        type: 'multiple',
        subLinks: [
            // {
            //     label: 'Pending',
            //     path: '/return/pending',
            // },
            {
                label: 'Completed',
                path: '/return/completed',
            },
        ],
    },
    {
        label: 'Order Control',
        path: 'ordercontrol',
        type: 'multiple',
        subLinks: [
            {
                label: 'New',
                path: '/ordercontrol/new',
            },
            {
                label: 'In Transit',
                path: '/ordercontrol/intransit',
            },
            {
                label: 'Ready for Pickup',
                path: '/ordercontrol/pickup',
            },
            {
                label: 'Completed Order',
                path: '/ordercontrol/completed',
            },
            {
                label: 'Cancelled',
                path: '/ordercontrol/cancelled',
            },
            {
                label: 'Pending',
                path: '/ordercontrol/undelivered',
            },
            {
                label: 'Delivered',
                path: '/ordercontrol/delivered',
            },
        ],
    },
    {
        label: 'Inventory',
        path: 'inventory',
        type: 'multiple',
        subLinks: [
            {
                label: 'Add Inventory',
                path: '/inventory/add',
            },
            {
                label: 'Active Inventory',
                path: '/inventory/active',
            },
        ],
    },
    {
        label: 'Communications',
        path: 'communications',
        type: 'multiple',
        subLinks: [
            {
                label: 'Email Template',
                path: '/communications/email',
            },
            {
                label: 'SMS Template',
                path: '/communications/sms',
            },
        ],
    },
    {
        label: 'Campaign',
        path: 'campaign',
        type: 'multiple',
        subLinks: [
            {
                label: 'Create Campaign ',
                path: '/campaign/create',
            },
            {
                label: 'Email template',
                path: '/campaign/template',
            },
        ],
    },
    {
        label: 'Logs',
        path: 'logs',
        type: 'multiple',
        subLinks: [
            {
                label: 'Email',
                path: '/logs/emailLogs',
            },
            {
                label: 'SMS',
                path: '/logs/smsLogs',
            },
        ],
    },
    {
        label: 'Questions & Answers',
        path: '/questions_answers',
        type: 'single',
    },

    {
        label: 'Messages',
        path: '/messages',
        type: 'single',
    },

    {
        label: 'Dynamic Pages',
        path: 'return',
        type: 'multiple',
        subLinks: [
            {
                label: 'Dynamic Fields',
                path: '/dynamic/pages',
                type: 'single',
            },
            {
                label: 'Dynamic Tables',
                path: '/dynamic/table/pages',
                type: 'single',
            },
        ],
    },
    {
        label: 'Settings',
        path: 'settings',
        type: 'multiple',
        subLinks: [
            {
                label: 'Static Pages',
                path: '/settings/static',
            },
            {
                label: 'Banner Image',
                path: '/settings/banner',
            },
            {
                label: 'Pallet Pricing',
                path: '/settings/palletPricing',
            },
            // {
            //     label: 'Category',
            //     path: '/settings/category',
            // },
            // {
            //     label: 'Condition',
            //     path: '/settings/condition',
            // },
            {
                label: 'Location',
                path: '/settings/location',
            },
            {
                label: 'General Settings',
                path: '/settings/general',
            },
            {
                label: 'Maintanence',
                path: '/settings/maintanence',
            },

            {
                label: 'Bid Increments',
                path: '/settings/bid_increments',
            },
            {
                label: 'Tax Code',
                path: '/settings/tax_code',
            },
            {
                label: 'Appointments',
                path: '/appointment/all',
            },
            {
                label: 'UI Settings',
                path: '/ui_settings',
            },
            {
                label: 'Language Manager',
                path: '/languageManager',
            },
            {
                label: 'Plugins',
                path: '/plugins',
            },
            {
                label: 'Subscribers',
                path: '/subscribers',
            },
            {
                label: 'Change Password',
                path: '/change_password',
            },
        ],
    },
    {
        label: 'Report',
        path: '/report',
        type: 'single',
    },
]

export const ALL_PAGINATION = [
    {
        value: '20',
        show: '20 Results per page',
    },
    {
        value: '40',
        show: '40 Results per page',
    },
    {
        value: '60',
        show: '60 Results per page',
    },
]
