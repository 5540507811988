import React, { useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '@/product//common/api'
import { response } from '@/product//context/common'

import {
    COMMON_VALUES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    SWITCH_LANGUAGE,
    SET_MEMBERSHIP,
    LOGIN_MODAL,
    SIGNUP_MODAL,
    FORGOT_PASSWORD_MODAL,
    SET_PROFILE_POPUP,
} from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        allCategory: [],
        allBusinessCategory: [],
        allOrganization: [],
        allVatCategory: [],
        allLocations: [],
        allTimeZone: [],
        allEventType: [],
        allCondition: [],
        allUISettings: [],
        allSubCategories: [],
        alldata_all_dropdown: [],
        allNotifications: [],
        allConfigurationVariables: {},
        allMaintanenceData: null,
        configFeatures: {},
        configVariables: {},
        allBidIncrements: [],
        responseStatus: null,
        languages: [],
        pharases: {},
        currentLanguage: 'for_spanish',
        allBiddingPlan: [],
        membership: null,
        allHomeBanner: [],
        loginModal: false,
        signupModal: false,
        forgetPasswordModal: false,
        showSeller: false,
        profilePopup: null,
    }

    const { i18n } = useTranslation()

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getGlobalVariable = async (type) => {
        const [res] = await Promise.all([apiCall('post', 'getDefault', {}, '', 'common')])
        if (res?.data?.status && res.data.status === 'success') {
            dispatch({
                type: COMMON_VALUES,
                payload: {
                    allCategory: res.data.data.responseData.tableValues.allcategorytypes,
                    allSubCategories: res.data.data.responseData.tableValues.allsubCategoryTypes,
                    allOrganization: res.data.data.responseData.tableValues.allorganization,
                    allBusinessCategory:
                        res.data.data.responseData.tableValues.allbusiness_category,
                    allVatCategory: res.data.data.responseData.tableValues.allvat_registration,
                    allNotifications: res.data.data.responseData.tableValues.allall_notifications,
                    allTimeZone: res.data.data.responseData.tableValues.alltime_zone,
                    allEventType: res.data.data.responseData.tableValues.allevent_type,
                    allConfigurationVariables: res.data.data.responseData.variables,
                    allMaintanenceData: res.data.data.responseData.maintanenceData,
                    allLocations: res.data.data.responseData.tableValues.alllocations,
                    allCondition: res.data.data.responseData.tableValues.allconditiontypes,
                    allUISettings: res.data.data.responseData.tableValues.allui_settings,
                    allBidIncrements: res.data.data.responseData.allBidIncrements,
                    bidIncrementDefault: res.data.data.responseData.bidIncrementDefault,
                    alldata_all_dropdown: res.data.data.responseData.tableValues
                        .alldata_all_dropdown
                        ? res.data.data.responseData.tableValues.alldata_all_dropdown.filter(
                              (value) => value.active != 2,
                          )
                        : [],
                    configFeatures: res.data.data.responseData.features,
                    configVariables: res.data.data.responseData.variables,
                    languages: res.data.data.responseData.languages,
                    phrases: res.data.data.responseData.phrases,
                    allBiddingPlan: res.data.data.responseData.tableValues.allbidding_plan,
                    allHomeBanner: res.data.data.responseData.tableValues.allhome_banner?.filter(
                        (value) => value.active === 1,
                    ),
                },
            })

            //set reload the language phrase
            global.languages = res.data.data.responseData.languages
            global.phrases = res.data.data.responseData.phrases
            for (let i = 0; i < global.languages.length; i++) {
                i18n.addResourceBundle(
                    global.languages[i],
                    'translations',
                    global.phrases[global.languages[i]],
                    true,
                    true,
                )
            }
            i18n.changeLanguage('for_spanish')
        }
    }

    const switchLanguage = (data) =>
        dispatch({
            type: SWITCH_LANGUAGE,
            payload: data,
        })

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const setCurrentMembership = (payload) =>
        dispatch({
            type: SET_MEMBERSHIP,
            payload: {
                membership: payload,
            },
        })

    const setLoginModal = () => {
        dispatch({
            type: LOGIN_MODAL,
        })
    }
    const setSignupModal = (payload, show) => {
        dispatch({
            type: SIGNUP_MODAL,
            showSeller: show ? show : false,
        })
    }
    const setForgetPasswordModal = () => {
        dispatch({
            type: FORGOT_PASSWORD_MODAL,
        })
    }

    const setProfilePopup = (data) => {
        dispatch({
            type: SET_PROFILE_POPUP,
            payload: data,
        })
    }

    return (
        <CommonContext.Provider
            value={{
                allCategory: state.allCategory,
                allBusinessCategory: state.allBusinessCategory,
                allOrganization: state.allOrganization,
                allVatCategory: state.allVatCategory,
                allNotifications: state.allNotifications,
                allSubCategories: state.allSubCategories,
                allLocations: state.allLocations,
                allCondition: state.allCondition,
                allTimeZone: state.allTimeZone,
                allEventType: state.allEventType,
                allUISettings: state.allUISettings,
                allBidIncrements: state.allBidIncrements,
                alldata_all_dropdown: state.alldata_all_dropdown,
                allConfigurationVariables: state.allConfigurationVariables,
                allMaintanenceData: state.allMaintanenceData,
                allBiddingPlan: state.allBiddingPlan,
                membership: state.membership,
                allHomeBanner: state.allHomeBanner,
                configFeatures: state.configFeatures,
                configVariables: state.configVariables,
                languages: state.languages,
                currentLanguage: state.currentLanguage,
                responseStatus: state.responseStatus,
                loginModal: state.loginModal,
                signupModal: state.signupModal,
                forgetPasswordModal: state.forgetPasswordModal,
                showSeller: state.showSeller,
                profilePopup: state.profilePopup,
                setLoginModal,
                setSignupModal,
                setForgetPasswordModal,
                switchLanguage,
                getGlobalVariable,
                clearResponse,
                setCurrentMembership,
                setProfilePopup,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
