import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import './Dialog.css'
import { Button } from '@material-ui/core'

export default function FormDialog(props) {
    const open = props.open
    const openFunction = props.function
    const title = props.title
    const handleClose = props.handleClose

    return (
        <>
            <Dialog
                className={`${props.className} customDialog`}
                open={open}
                onClose={openFunction}
                aria-labelledby="form-dialog-title"
                maxWidth={props.maxWidth}
                disableBackdropClick={props.disableBackdropClick ? true : false}
            >
                <div className="cdTop lisitnModal">
                    <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                    {handleClose ? (
                        <Button id="dialog_closeBtn" className="closeBtn" onClick={handleClose}>
                            <span className="material-icons">close</span>
                        </Button>
                    ) : null}
                </div>
                <DialogContent>{props.children}</DialogContent>
            </Dialog>
        </>
    )
}
