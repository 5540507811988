import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title, description, keywords, url, image }) => {
    return (
        <Helmet>
            <title>{`${title ? title + ' | ' : ''} Subastas Pro`}</title>
            <meta
                name="title"
                content={`${title ? title + ' | ' : ''} Subastas Pro`}
                data-react-helmet="true"
            />
            <meta name="description" content={description}></meta>
            <meta name="keywords" content={keywords}></meta>

            <meta property="og:url" content={url} />
            <meta property="og:title" content={`${title ? title + ' | ' : ''} Subastas Pro`} />
            <meta property="og:description" content={description} />
            {/* <meta
                property="og:image"
                content={
                    image ? image : `${window.location.origin}/assets/images/short.png`
                }
            />
            <meta
                property="og:image:secure_url"
                content={
                    image ? image : 
                }
            /> */}
        </Helmet>
    )
}

export const SEOHome = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta
                name="keywords"
                content="subastas, ventas, subastas.pro, subastas pro, vehículos, nuevos, usados, profesionales"
            />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta
                name="google-site-verification"
                content="google-site-verification=dQmyAK30vcT2KPCQoM-km3pOcom6ddWW6cR7cRZHIkY"
            />
            <meta name="content-language" content="Es" />
            <meta name="subject" content="subastas" />
            <meta name="author" content="subastas pro" />
            <meta name="identifier-Url" content="www.subastas.pro/" />
            <link rel="canonical" href="https://www.subastas.pro/" />
            <meta name="reply-to" content="support@subastas.pro" />
            <meta name="revisit-after" content="10 days" />
            <meta name="robots" content="all" />
            <meta name="rating" content="general" />
            <meta name="distribution" content="global" />
            <meta name="expires" content="never" />
            <meta name="geography" content="spain" />
            <meta name="copyright" content="©subastas.pro" />
            <meta name="category" content="automotive" />
            <meta name="page-topic" content="document" />
            <meta name="classification" content="online Auctions" />
            <meta name="date-revision-ddmmyyyy" content="20250101" />
            <meta name="audience" content="general" />
            <meta name="publisher" content="https://google.com+subastas" />
        </Helmet>
    )
}

export const CommonSEO = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
        </Helmet>
    )
}

export default SEO
