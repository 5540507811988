import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import './CustomDialog.css'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

function CustomDialog(props) {
    const open = props.open
    const openFunction = props.function
    const title = props.title
    const maxWidth = props.maxWidth
    return (
        <Dialog
            className={`customDialog ${props.className}`}
            open={open}
            onClose={openFunction}
            maxWidth={maxWidth}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <div className="d-flex align-items-center justify-content-end w-100">
                    {title}
                    <div className="ssmHead d-flex justify-content-center">
                        <button
                            id="dialog_close"
                            type="button"
                            className="close"
                            onClick={() => openFunction()}
                        >
                            <svg width="14" height="14" viewBox="0 0 29 28" fill="none">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.000976562 3.45115L2.71626 0.995117L14.4829 11.6383L26.2857 0.995117L29.001 3.45115L17.1982 14.0943L29.001 24.7702L26.2857 27.2263L14.4829 16.5504L2.71626 27.2263L0.000976562 24.7702L11.7676 14.0943L0.000976562 3.45115Z"
                                    fill="#4D4E56"
                                />
                            </svg>
                        </button>
                    </div>
                    {/* <IconButton onClick={() => openFunction()}>
                            <CloseIcon />
                        </IconButton> */}
                </div>
            </DialogTitle>
            <DialogContent>{props.children}</DialogContent>
        </Dialog>
    )
}
export default CustomDialog
