import React, { useContext, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Divider } from '@material-ui/core'
import { handleRedirectInternal } from '@/product/common/components'
import AuthContext from '@/product/context/auth/authContext'
import { useTranslation } from 'react-i18next'
import LoginComponent from './loginComponent'
import { loginSchema } from './loginSchema'
import SEO from '@/utils/SEO'
import './Login.css'
import CustomCommonContext from '../../custom/context/common/commonContext'
import CustomDialog from '@/custom/components/organisms/CustomDialog'
//login page
const Login = () => {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const vData = loginSchema()
    //useTranslation for language change functionality
    const { t } = useTranslation()
    const customCommonContext = useContext(CustomCommonContext)
    const {
        signupModal,
        setSignupModal,
        loginModal,
        setLoginModal,
        forgetPasswordModal,
        setForgetPasswordModal,
    } = customCommonContext

    const { isAuthenticated } = authContext

    const handleredirection = () => {
        // handleRedirectInternal(history, '')
    }
    //authcontext===>user authentication
    useEffect(() => {
        if (isAuthenticated) {
            handleredirection()
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (forgetPasswordModal) handleRedirectInternal(history, 'forgot_password')
    }, [forgetPasswordModal])

    const forgetModalChange = () => {
        console.log('forgetModalChange')
        setLoginModal(false)
        setForgetPasswordModal(true)
    }

    const loginRedirection = () => {
        setLoginModal(false)
    }

    const registerModalChange = () => {
        setLoginModal(false)
        setSignupModal(true)
    }

    return (
        <>
            <CustomDialog
                className="modalView loginVIew"
                function={(event, reason) => {
                    if (reason && reason === 'backdropClick') return
                    setLoginModal(!loginModal)
                }}
                open={loginModal}
                maxWidth="sm"
            >
                <LoginComponent
                    initialValues={vData.initialValues}
                    googleSignIn={true}
                    validationArray={vData.validationArray}
                    rememberMe={true}
                    loginInfo={vData.login.formData}
                    onSuccess={loginRedirection}
                    forgetModalChange={forgetModalChange}
                    registerModalChange={registerModalChange}
                />
            </CustomDialog>
        </>
    )
}

export default Login
