import React, { useContext, useEffect, useState } from 'react'
import RegisterComponent from '../../product/components/species/dynamic/common/Register/RegisterComponent'
import RegistrationLayout from '../../custom/components/templates/RegistrationLayout'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import ProductCommonContext from '../../product/context/common/commonContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CommonContext from '../../custom/context/common/commonContext'
import AlertContext from '../../product/context/alert/alertContext'
// import AuthContext from '../../custom/context/auth/authContext'
import RadioBox from '../../product/components/atoms/RadioBox'
import CheckBox from '../../product/components/atoms/CheckBox'
import { useFormik, connect, Form, Formik } from 'formik'
import { FilterFramesOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import Backdrop from '@material-ui/core/Backdrop'
import { Button, Fade } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { LOGO, SITE_NAME } from '../../utils'
import { Modal } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Document } from 'react-pdf'
import { stubFalse } from 'lodash'
import './Registration.css'
import * as Yup from 'yup'
import DynamicContext from '@/product/context/dynamic/dynamicContext'
import SEO from '@/utils/SEO'
import CustomDialog from '@/custom/components/organisms/CustomDialog'
import Subcription from '@/custom/components/molecules/Subcription'

const useStyles = makeStyles((theme, maxWidth) => ({
    modal: {
        display: 'flex',
        padding: theme.spacing(1),
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: '100%',
        maxWidth: '55rem',
    },
}))

const Registration = (props) => {
    const classes = useStyles()
    const { t } = useTranslation()
    // const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const history = useHistory()
    const customCommonContext = useContext(CustomCommonContext)
    const { setAlert } = alertContext
    // const { register, responseStatus, clearResponse } = authContext
    const {
        signupModal,
        setSignupModal,
        loginModal,
        setLoginModal,
        allOrganization,
        showSeller,
        currentLanguage,
    } = customCommonContext

    const commonContext = useContext(CommonContext)
    const productCommonContext = useContext(ProductCommonContext)
    const dynamicContext = useContext(DynamicContext)

    const { getAllDynamicArray, dynamicValue } = dynamicContext

    const [toggleSignup, setToggleSignup] = useState({
        popup: signupModal,
        onlySeller: false,
    })

    const [updatedValues, setUpdatedValues] = useState({})
    const [customValidation, setCustomValidation] = useState([])
    const [customValidationSeller, setCustomValidationSeller] = useState([])
    const [viewSubscription, setViewSubscription] = useState(false)

    const [customInitialSeller, setCustomInitialSeller] = useState([])
    const [customInitialBuyer, setCustomInitialBuyer] = useState([])
    const [fieldValue, setFieldValue] = useState([])
    const [sellerValue, setSellerValue] = useState([])
    const { configFeatures } = commonContext
    const { static_page, getStaticPage, allCountries, allStates } = productCommonContext
    let [termsLink, setTermsLink] = useState([])
    const [SubmitBtnLabel, setSubmitBtnLabel] = useState('')
    const [phoneVerify, setPhoneVerify] = useState(false)
    const [regType, setRegType] = useState(0)
    //const orgid = props.match.params.oid
    useEffect(() => {
        setToggleSignup({ popup: signupModal })
    }, [signupModal])

    // YUP Validation array to ensure all required fields are given values

    useEffect(() => {
        getStaticPage({ id: 'terms_of_use' })
    }, [])
    useEffect(() => {
        const value = t('create_my_account')
        setSubmitBtnLabel(value)
    }, [t('create_my_account')])
    useEffect(() => {
        setTermsLink(static_page?.record?.file_path ? static_page.record.file_path : null)
    }, [static_page])

    const viewTerms = () =>
        window.open(`${global.front_url}/condiciones-generales-especiales-contratacion`)

    // Set Initial values

    useEffect(() => {
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: 'asc',
                orderby: 'df.show_order',
                table: 'dynamic_fields',
                filters: {
                    action: {
                        value: 3,
                        type: 'in',
                        field: 'df.view_page',
                    },
                    forOrganization: {
                        value: 1,
                        type: 'in',
                        field: 'df.for_organization',
                    },
                    active: {
                        value: '1',
                        type: 'in',
                        field: 'df.active',
                    },
                },
            },
            'forRegOrganization',
        )
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: 'asc',
                orderby: 'df.show_order',
                table: 'dynamic_fields',
                filters: {
                    action: {
                        value: 3,
                        type: 'in',
                        field: 'df.view_page',
                    },
                    forBuyer: {
                        value: 1,
                        type: 'in',
                        field: 'df.for_buyer',
                    },
                    active: {
                        value: '1',
                        type: 'in',
                        field: 'df.active',
                    },
                },
            },
            'forRegisterBuyer',
        )
    }, [])
    useEffect(() => {
        if (dynamicValue.from === 'forRegOrganization') {
            const array = [
                'seller_type',
                'email',
                'password',
                'confirm_password',
                'registration_terms',
            ]
            const val = []
            if (dynamicValue.records) {
                let dynamicValueAssign = []
                dynamicValue.records.map((item) => {
                    item.view_on
                        ? item.view_on.includes('2')
                            ? dynamicValueAssign.push(item)
                            : {}
                        : dynamicValueAssign.push(item)
                })
                dynamicValueAssign.push({
                    name: 'market_status',
                    value: 'draft',
                    type: 'default',
                    active: 1,
                })
                // const temp = dynamicValueAssign.map((item) => {
                //     if (item.name == 'country') {
                //         item['disabled'] = true
                //     }
                //     if (item.name == 'phone') {
                //         item.countryCodeEditable = false
                //     }
                //     return item
                // })
                dynamicValueAssign = dynamicValueAssign.filter((e) => array.includes(e.name))
                dynamicValueAssign.map((e) => {
                    let index = array.indexOf(e.name)
                    e.class = 'col-sm-12 col-12'
                    if (e.name == 'registration_terms') {
                        e.label = [
                            <a target="_blank" onClick={viewTerms}>
                                <u>
                                    {t(
                                        'Acepto las condiciones de uso y la Información básica de Protección de Datos',
                                    )}{' '}
                                </u>
                            </a>,
                        ]
                    }
                    val[index] = e
                })
                setSellerValue(val)
            }
        }
        if (dynamicValue.from === 'forRegisterBuyer') {
            if (dynamicValue.records) {
                let dynamicValueAssign = []
                dynamicValue.records.map((item) => {
                    item.view_on
                        ? item.view_on.includes('1')
                            ? dynamicValueAssign.push(item)
                            : {}
                        : dynamicValueAssign.push(item)
                })
                dynamicValueAssign.push({
                    name: 'market_status',
                    value: 'draft',
                    type: 'default',
                    active: 1,
                })

                const temp = dynamicValueAssign.map((item) => {
                    if (item.name == 'country') {
                        item['disabled'] = true
                    }
                    if (item.name == 'phone') {
                        item.countryCodeEditable = false
                    }
                    return item
                })
                setFieldValue(temp)
            }
        }
    }, [dynamicValue, currentLanguage])
    let date = new Date()
    let currentDate = new Date()
    let endDate = new Date()
    endDate.setDate(endDate.getDate() + 30)

    useEffect(() => {
        let customValidationTemp = {}
        let customInitial = {
            phone_verified: 0,
            termCheck: 0,
            seller_type: 0,
            status: 'active',
            admin: 0,
            autoFocus: false,
            google_id: '',
            country: 'ES',
        }
        let customOrgInitial = {
            phone_verified: 0,
            termCheck: 0,
            admin: 1,
            autoFocus: false,
            google_id: '',
            country: 'ES',
            organization: 0,
            status: 'active',
            emp_type: 'seller',
            membership: 'free',
            membership_period: 'monthly',
            trial_activated: 1,
            membership_date:
                currentDate.getFullYear() +
                '/' +
                (parseInt(currentDate.getMonth()) + 1) +
                '/' +
                currentDate.getDate(),
            membership_enddate:
                endDate.getFullYear() +
                '/' +
                (parseInt(endDate.getMonth()) + 1) +
                '/' +
                endDate.getDate(),
        }

        customValidationTemp = {
            email: Yup.string()
                .email(t('Invalid email format'))
                .max(250, t('Maximum 250 characters'))
                .required(t('Required')),
            password: Yup.string().min(8, t('Minimum 8 characters')).required(t('Required')),
            confirm_password: Yup.string()
                .oneOf([Yup.ref('password')], t('Password does not match'))
                .required(t('Required')),
            first_name: Yup.string()
                // .matches(/^[aA-zZ\s]+$/, t('Only alphabets are allowed for this field '))
                .min(2, t('Mininum 2 characters'))
                .max(15, t('Maximum 15 characters'))
                .required(t('Required!')),
            last_name: Yup.string()
                // .matches(/^[aA-zZ\s]+$/, t('Only alphabets are allowed for this field '))
                .min(2, t('Mininum 2 characters'))
                .max(15, t('Maximum 15 characters'))
                .required(t('Required!')),
            phone: Yup.string()
                .trim()
                .nullable()
                .matches(/^[0-9- +()]*$/g, t('Only numbers are allowed'))
                .required(t('Required!')),
            zip: Yup.string()
                .trim()
                .matches(/^[0-9- +()]*$/g, t('Only numbers are allowed'))
                .max(6, t('Maximum 6 characters'))
                .required(t('Required')),
            city: Yup.string().required(t('Required')),
            registration_terms: Yup.boolean().oneOf(
                [true],
                'Por favor acepte los términos para continuar.',
            ),
            termCheck: Yup.boolean().oneOf([true], 'Por favor acepte los términos para continuar.'),
            autoFocus: false,
        }
        setCustomInitialSeller(customOrgInitial)
        setCustomInitialBuyer(customInitial)
        setCustomValidation(customValidationTemp)
    }, [global.languages])

    // Mapped over for individual registration fields

    const additionalFieldUpdate = (data) => {
        setUpdatedValues(data)
    }

    const registerModalChange = () => {
        setSignupModal(false)
        setLoginModal(true)
    }

    useEffect(() => {
        if (showSeller) {
            setRegType(1)
        }
    }, [showSeller])
    const onSuccess = (data) => {}
    return (
        <>
            <CustomDialog
                className="registerDialogVIew"
                function={(event, reason) => {
                    if (reason && reason === 'backdropClick') return
                    setSignupModal(!signupModal)
                }}
                open={signupModal}
            >
                <RegistrationLayout>
                    <div className="regContainer container">
                        <div className="rgTypeWrpr d-flex align-items-center justify-content-center">
                            {/* <label className="m-0 mr-4">{t('register_as_a')}</label>
                            {!showSeller && (
                                <Button
                                    id="reg_bidder"
                                    className={`tabBtns ${regType === 0 ? 'active ' : ''}`}
                                    onClick={() => setRegType(0)}
                                >
                                    <span className="material-icons">
                                        {regType === 0
                                            ? 'radio_button_checked '
                                            : 'radio_button_unchecked'}
                                    </span>
                                    {t('bidder')}
                                </Button>
                            )} */}
                            {/* 
                            <Button
                                id="reg_seller"
                                className={`tabBtns ${regType === 1 ? 'active ' : ''}`}
                                onClick={() => setRegType(1)}
                            >
                                <span className="material-icons">
                                    {regType === 1
                                        ? 'radio_button_checked '
                                        : 'radio_button_unchecked'}
                                </span>
                                {t('seller')}
                            </Button> */}
                        </div>
                        {/* {regType === 0 ? (
                            <div className="regForm">
                                <RegisterComponent
                                    googleSignUp={true}
                                    fieldValue={fieldValue}
                                    customInitial={customInitialBuyer}
                                    customValidation={customValidation}
                                    additionalFieldUpdate={additionalFieldUpdate}
                                    SubmitBtnLabel={SubmitBtnLabel}
                                    phoneVerify={true}
                                    termsCondition={true}
                                    onSuccess={onSuccess}
                                    autoRegisterAfterVerify={true}
                                />
                            </div>
                        ) : ( */}
                        <div className="regForm">
                            <RegisterComponent
                                googleSignUp={true}
                                fieldValue={sellerValue}
                                customInitial={customInitialSeller}
                                customValidation={customValidation}
                                additionalFieldUpdate={additionalFieldUpdate}
                                SubmitBtnLabel={SubmitBtnLabel}
                                // phoneVerify={true}
                                // termsCondition={true}
                                viewSubscription={viewSubscription}
                                setViewSubscription={setViewSubscription}
                                onSuccess={onSuccess}
                                autoRegisterAfterVerify={true}
                                emailVerify={true}
                                customPopUp={true}
                            />
                        </div>
                        {/* )} */}
                    </div>
                </RegistrationLayout>
                <p className="forPass">
                    {t('Ya tienes una cuenta?')}
                    <Button onClick={registerModalChange} className="aPass">
                        {t('Identificarse')}
                    </Button>
                </p>
            </CustomDialog>

            <Subcription
                viewSubscription={viewSubscription}
                setViewSubscription={setViewSubscription}
            />
        </>
    )
}

export default Registration
