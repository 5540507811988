import React, { useState, useContext, useEffect } from 'react'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import './Maintenance.css'

const Maintanence = (props) => {
    const [currentIP, setCurrentIP] = useState(props.ipAddress)
    const [blockedContent, setBlockedContent] = useState(false)
    const customCommonContext = useContext(CustomCommonContext)

    const { allMaintanenceData } = customCommonContext

    useEffect(() => {
        if (window.location.pathname == '/maintanence') {
            setBlockedContent(true)
        } else {
            if (allMaintanenceData) {
                if (parseInt(allMaintanenceData?.active, 10) === 1) {
                    if (allMaintanenceData.exclude_route && window.location.pathname) {
                        setBlockedContent(false)
                        if (
                            allMaintanenceData.exclude_route
                                .toString()
                                .split(',')
                                .includes(window.location.pathname)
                        ) {
                            setBlockedContent(true)
                            window.location.href = '/maintanence'
                        }
                    }
                    if (allMaintanenceData.exclude_ip && currentIP) {
                        if (
                            allMaintanenceData.exclude_ip.toString().split(',').includes(currentIP)
                        ) {
                            setBlockedContent(true)
                            window.location.href = '/maintanence'
                        } else {
                            setBlockedContent(false)
                        }
                    }
                }
            }
        }
    }, [allMaintanenceData, window.location.pathname, currentIP])

    return (
        <>
            {parseInt(allMaintanenceData?.active, 10) === 1 && blockedContent ? (
                <div className="maintenancePage">
                    <img src="/assets/svg/maintenance.svg" />
                    <h2>{allMaintanenceData?.message}</h2>
                </div>
            ) : null}
        </>
    )
}
export default Maintanence
