import React, { useState, useEffect, useContext } from 'react'
import ProductCommonContext from '../../context/common/commonContext'
import CustomCommonContext from '../../../custom/context/common/commonContext'
import DynamicContext from '../../context/dynamic/dynamicContext'

const InitialFunction = (props) => {
    const { getAllCountries, getAllStates, getAllCities } = useContext(ProductCommonContext)
    const { getGlobalVariable } = useContext(CustomCommonContext)
    const { getAllDynamicFields, getAllDynamicTables, getAllDynamicPagesFields } =
        useContext(DynamicContext)

    useEffect(() => {
        getGlobalVariable()
        getAllDynamicFields({
            page: 1,
            limit: 1000,
            order: 'asc',
            orderby: 'df.show_order',
            table: 'dynamic_fields',
            filters: {
                active: {
                    value: '1',
                    type: 'in',
                    field: 'df.active',
                },
            },
        })
        getAllDynamicTables({
            page: 1,
            limit: 1000,
            order: 'asc',
            orderby: 'dt.show_order',
            table: 'dynamic_tables',
            filters: {},
        })
        getAllCountries()
        getAllStates()
        console.log('i am cityouter=')
        if (global?.Allcities) {
            console.log('i am city')
            getAllCities()
        }
    }, [])
    return <></>
}

export default InitialFunction
