import React, { useEffect, useLayoutEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Button, Drawer } from '@material-ui/core'
import './cookies.css'
import { useHistory } from 'react-router-dom'
import { handleRedirectInternal } from '@/product/common/components'
// import '@/utils/utils.css'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'

export default function SiteCookies() {
    const [cookies, setCookie, removeCookie] = useCookies()
    const [state, setState] = React.useState(true)
    const history = useHistory()
    // Example: Setting a cookie
    const handleSetCookie = () => {
        setCookie('username', 'John Doe', { path: '/' })
        setCookie('user', 'authenticated', { path: '/' })
        setState(false)
        window.location.reload()
    }

    // Example: Getting a cookie
    const getUsername = () => {
        const username = cookies.username || 'Guest'
    }

    // Example: Removing a cookie
    const handleRemoveCookie = () => {
        removeCookie()
        setState(false)
    }

    // Drawer state
    useEffect(() => {
        if (cookies.user == 'authenticated') {
            setState(false)
        }
    }, [cookies])

    useEffect(() => {
        if (state) {
            document.body.style = ''
        }
    })

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setState(open)
    }

    const removeBackdropShadow = {
        style: {
            backdropFilter: 'none', // Remove backdrop shadow
            backgroundColor: 'transparent', // Make the backdrop transparent
        },
    }

    return (
        <div>
            <React.Fragment key="bottom">
                <Drawer
                    anchor="bottom"
                    className="cookiesDrawer"
                    open={state}
                    onClose={toggleDrawer(false)}
                    BackdropProps={removeBackdropShadow}
                    ModalProps={{
                        disableBackdropClick: true,
                        disableEscapeKeyDown: true,
                    }}
                >
                    <div className="SiteCookiesModal">
                        <span className="material-icons-outlined icon">cookie</span>
                        <div>
                            <h4>Uso cookies</h4>
                            <p className="m-0">
                                En subastas.pro utilizamos cookies propias y de terceros para
                                posibilitar y mejorar su experiencia de navegación y para realizar
                                análisis estadísticos. Puedes aceptarlas y seguir navegando usando
                                la configuración de cookies proporcionada por subastas.pro o
                                rechazarlas e irte del portal.
                            </p>
                        </div>
                        <div className="cookiesBtnGrp">
                            <PrimaryButton onClick={handleSetCookie}>Aceptar</PrimaryButton>
                            <SecondaryButton onClick={handleRemoveCookie}>Rechazar</SecondaryButton>
                        </div>
                    </div>
                </Drawer>
            </React.Fragment>
        </div>
    )
}
