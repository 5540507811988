import React, { useState, useContext, useEffect } from 'react'
import { Button } from '@material-ui/core'
import './Footer.css'
import AutopltContext from '@/product/context/autopilot/autopltContext'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LOGO, SITE_NAME } from '@/utils'
import LanguageSwitch from '../LanguageSwitch'
import { mapData } from '@/product/common/components'
import { Link } from 'react-router-dom'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min'
import { useTranslation } from 'react-i18next'
import UserContext from '@/product/context/user/userContext'
// react Share
// import { FacebookIcon, LinkedinIcon, TwitterIcon } from 'react-share'

const Footer = () => {
    const { t } = useTranslation()
    const autopltContext = useContext(AutopltContext)
    const userContext = useContext(UserContext)

    const { addCustomer, responseStatus: responseStatusAutoPlt } = autopltContext
    const { submitContactUs, responseStatus } = userContext

    const validationArray = Yup.object({
        email: Yup.string().email(t('Invalid email format')).required(t('Required')),
    })

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            console.log('hits===>', values)
            submitContactUs(values)
        },
    })

    const subscribe = [
        {
            label: t('name'),
            placeholder: t('enter_your_name'),
            class: 'subsInp',
            type: 'text',
            variant: 'filled',
            name: 'name',
            formik: formik,
        },
        {
            label: t('email'),
            placeholder: t('enter_your_email'),
            class: 'subsInp',
            type: 'email',
            variant: 'filled',
            name: 'email',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusAutoPlt) {
            if (responseStatusAutoPlt.from === 'addCustomer') {
                if (responseStatusAutoPlt.status === 'success') {
                    formik.values.email = ''
                }
            }
        }
    }, [responseStatusAutoPlt])

    return (
        <footer className="noPrint">
            <div className="footCnt">
                <div className="customContainer">
                    <div className="footgridSplt">
                        <div className="footLinks">
                            <figure>
                                <img
                                    src="/assets/images/whiteLogo.png"
                                    className="img-responsive footerLogo"
                                />
                            </figure>
                            <ul>
                                <li>
                                    <Link
                                        id="footer_direct_sales"
                                        to="/buscar/subastas"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        subastas Ahora
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        id="footer_direct_sales"
                                        to="/buscar/ventas"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        {t('Ventas Activas')}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        id="footer_sell"
                                        to="/vender-vehiculo"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        {t('sell')}
                                    </Link>
                                </li>

                                <li>
                                    <Link
                                        id="footer_subastar"
                                        to="/subastar-vehiculo"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        {t('subastar')}
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/activar-alerta"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        Alertas
                                    </Link>
                                </li>

                                <li>
                                    <Link
                                        id="footer_faq"
                                        to="/preguntas-frequentes"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        Preguntas Frecuentes
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/contrataciones"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        Contrataciones
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        id="footer_about"
                                        to="/quienes-somos"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        {t('Sobre Nosotros')}
                                    </Link>
                                </li>

                                <li>
                                    <Link
                                        id="footer_contact"
                                        to="/contactarnos"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        {t('contact_us')}
                                    </Link>
                                </li>

                                {/* <li>
                                    <Link to="/legal_mentions">Menciones legales</Link>
                                </li> */}
                            </ul>
                            <ul className="legal_mentionsStyl">
                                <li>
                                    <Link
                                        to="/politica-cookies"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        Política de cookies
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/condiciones-generales-especiales-contratacion"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        Términos y condiciones
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/politica-privacidad"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        politica de privacidad
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/politica-proteccion-datos"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        politica de proteccion de datos
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/aviso-legal"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        aviso legal
                                    </Link>
                                </li>
                            </ul>
                            {/* <ul className="legal_mentionsStyl">
                                <li>
                                    <Link
                                        id="footer_legal_mentions"
                                        to="/menciones-legales"
                                        onClick={() =>
                                            window.scrollTo({ top: 0, behavior: 'smooth' })
                                        }
                                    >
                                        {t('legal_mentions')}
                                    </Link>
                                </li>
                            </ul> */}
                            {/* <div className="footAddres">
                                <div className="addresscard">
                                    <h4>{t('address')}</h4>
                                    <address>
                                        21460 SW Frontage Rd, <br /> Shorewood, IL, US
                                    </address>
                                </div>
                                <div className="addresscard">
                                    <h4>{t('hours')}</h4>
                                    <address>
                                        Mon-Fri: 10am - 7pm <br /> Sat-Sun: Closed
                                    </address>
                                </div>
                            </div> */}
                        </div>
                        <div className="customerGetintoForm">
                            <h5 className="getinTouchTit">{t('stay_in_touch')}</h5>
                            <h6 className="getinTouchPara">{t('sign_up_to_stay_up_to_date')}</h6>
                            <form onSubmit={formik.handleSubmit} className="userGetForm">
                                {Object.values(mapData(subscribe))}
                                <div className="SubBtn">
                                    <PrimaryButton
                                        type="submit"
                                        id="footer_subscribe"
                                        btnSize="small"
                                    >
                                        {t('subscribe')}
                                    </PrimaryButton>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div className="footBorder">
                    <div className="customContainer">
                        <div className="fixedFoot">
                            <div className="SocialIco">
                                <ul className="socilUl">
                                    <li>
                                        <NavLink to="">
                                            <svg width="24" height="24" viewBox="0 0 24 24">
                                                <defs>
                                                    <clipPath>
                                                        <rect
                                                            id="Rectangle_4801"
                                                            data-name="Rectangle 4801"
                                                            width="24"
                                                            height="24"
                                                            transform="translate(-1562 480)"
                                                            fill="#fff"
                                                        />
                                                    </clipPath>
                                                </defs>
                                                <g
                                                    id="Mask_Group_10"
                                                    data-name="Mask Group 10"
                                                    transform="translate(1562 -480)"
                                                    clipPath="url(#clip-path)"
                                                >
                                                    <path
                                                        id="linkedin-in-brands"
                                                        d="M5.372,24H.4V7.977H5.372ZM2.882,5.791A2.9,2.9,0,1,1,5.763,2.882,2.906,2.906,0,0,1,2.882,5.791ZM23.995,24H19.03V16.2c0-1.859-.038-4.243-2.587-4.243-2.587,0-2.983,2.02-2.983,4.109V24H8.489V7.977h4.772v2.186h.07a5.228,5.228,0,0,1,4.708-2.588C23.074,7.575,24,10.891,24,15.2V24Z"
                                                        transform="translate(-1562 480)"
                                                        fill="#fff"
                                                    />
                                                </g>
                                            </svg>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="">
                                            <svg width="24" height="24" viewBox="0 0 24 24">
                                                <defs>
                                                    <clipPath>
                                                        <rect
                                                            id="Rectangle_4802"
                                                            data-name="Rectangle 4802"
                                                            width="24"
                                                            height="24"
                                                            transform="translate(-1531 480)"
                                                            fill="#fff"
                                                        />
                                                    </clipPath>
                                                </defs>
                                                <g
                                                    id="Mask_Group_11"
                                                    data-name="Mask Group 11"
                                                    transform="translate(1531 -480)"
                                                    clipPath="url(#clip-path)"
                                                >
                                                    <path
                                                        id="twitter-brands"
                                                        d="M21.533,7.112c.015.213.015.426.015.64A13.9,13.9,0,0,1,7.553,21.746,13.9,13.9,0,0,1,0,19.538a10.175,10.175,0,0,0,1.188.061,9.851,9.851,0,0,0,6.107-2.1,4.927,4.927,0,0,1-4.6-3.411,6.2,6.2,0,0,0,.929.076A5.2,5.2,0,0,0,4.919,14,4.919,4.919,0,0,1,.975,9.168V9.107A4.954,4.954,0,0,0,3.2,9.731,4.926,4.926,0,0,1,1.675,3.152,13.981,13.981,0,0,0,11.817,8.3,5.553,5.553,0,0,1,11.7,7.173a4.923,4.923,0,0,1,8.513-3.365A9.684,9.684,0,0,0,23.33,2.619,4.906,4.906,0,0,1,21.168,5.33,9.861,9.861,0,0,0,24,4.569a10.573,10.573,0,0,1-2.467,2.543Z"
                                                        transform="translate(-1531 480)"
                                                        fill="#fff"
                                                    />
                                                </g>
                                            </svg>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="">
                                            <svg width="24" height="24" viewBox="0 0 24 24">
                                                <defs>
                                                    <clipPath>
                                                        <rect
                                                            id="Rectangle_4804"
                                                            data-name="Rectangle 4804"
                                                            width="24"
                                                            height="24"
                                                            transform="translate(-1500 480)"
                                                            fill="#fff"
                                                        />
                                                    </clipPath>
                                                </defs>
                                                <g
                                                    id="Mask_Group_9"
                                                    data-name="Mask Group 9"
                                                    transform="translate(1500 -480)"
                                                    clipPath="url(#clip-path)"
                                                >
                                                    <path
                                                        id="facebook-f-brands"
                                                        d="M13.085,13.5l.667-4.343H9.584V6.338a2.172,2.172,0,0,1,2.449-2.347h1.895V.293A23.105,23.105,0,0,0,10.564,0C7.132,0,4.888,2.08,4.888,5.846v3.31H1.073V13.5H4.888V24h4.7V13.5Z"
                                                        transform="translate(-1495.5 480)"
                                                        fill="#fff"
                                                    />
                                                </g>
                                            </svg>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="">
                                            <svg width="24" height="24" viewBox="0 0 24 24">
                                                <defs>
                                                    <clipPath>
                                                        <rect
                                                            data-name="Rectangle 4803"
                                                            width="24"
                                                            height="24"
                                                            transform="translate(-1469 480)"
                                                            fill="#fff"
                                                        />
                                                    </clipPath>
                                                </defs>
                                                <g
                                                    data-name="Mask Group 12"
                                                    transform="translate(1469 -480)"
                                                >
                                                    <path
                                                        d="M12,7.549A6.152,6.152,0,1,0,18.151,13.7,6.142,6.142,0,0,0,12,7.549ZM12,17.7a4,4,0,1,1,4-4,4.007,4.007,0,0,1-4,4ZM19.837,7.3A1.435,1.435,0,1,1,18.4,5.863,1.432,1.432,0,0,1,19.837,7.3Zm4.075,1.456a7.1,7.1,0,0,0-1.938-5.028,7.148,7.148,0,0,0-5.028-1.938c-1.981-.112-7.919-.112-9.9,0A7.137,7.137,0,0,0,2.019,3.721,7.124,7.124,0,0,0,.08,8.749c-.112,1.981-.112,7.919,0,9.9a7.1,7.1,0,0,0,1.938,5.028,7.157,7.157,0,0,0,5.028,1.938c1.981.112,7.919.112,9.9,0a7.1,7.1,0,0,0,5.028-1.938,7.148,7.148,0,0,0,1.938-5.028c.112-1.981.112-7.913,0-9.894Zm-2.559,12.02a4.049,4.049,0,0,1-2.281,2.281c-1.579.626-5.327.482-7.073.482s-5.5.139-7.073-.482a4.049,4.049,0,0,1-2.281-2.281c-.626-1.579-.482-5.327-.482-7.073s-.139-5.5.482-7.073A4.049,4.049,0,0,1,4.926,4.348c1.579-.626,5.327-.482,7.073-.482s5.5-.139,7.073.482a4.049,4.049,0,0,1,2.281,2.281c.626,1.579.482,5.327.482,7.073S21.979,19.2,21.352,20.774Z"
                                                        transform="translate(-1468.996 478.299)"
                                                        fill="#fff"
                                                    />
                                                </g>
                                            </svg>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="">
                                            <svg width="24" height="24" viewBox="0 0 24 24">
                                                <defs>
                                                    <clipPath>
                                                        <rect
                                                            id="Rectangle_4807"
                                                            data-name="Rectangle 4807"
                                                            width="24"
                                                            height="24"
                                                            transform="translate(-1438 480)"
                                                            fill="#fff"
                                                        />
                                                    </clipPath>
                                                </defs>
                                                <g transform="translate(1438 -480)">
                                                    <path
                                                        id="youtube-brands"
                                                        d="M24.155,5.453a3.016,3.016,0,0,0-2.122-2.136c-1.872-.5-9.377-.5-9.377-.5s-7.5,0-9.377.5A3.016,3.016,0,0,0,1.158,5.453a31.635,31.635,0,0,0-.5,5.814,31.635,31.635,0,0,0,.5,5.814,2.971,2.971,0,0,0,2.122,2.1c1.872.5,9.377.5,9.377.5s7.5,0,9.377-.5a2.971,2.971,0,0,0,2.122-2.1,31.635,31.635,0,0,0,.5-5.814A31.635,31.635,0,0,0,24.155,5.453ZM10.2,14.836V7.7l6.273,3.569L10.2,14.836Z"
                                                        transform="translate(-1438.656 480.75)"
                                                        fill="#fff"
                                                    />
                                                </g>
                                            </svg>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="rightnavLinks">
                                <span className="footCopyright text-center pr-3" dir="ltr">
                                    {t('copyright')} © {new Date().getFullYear()}
                                </span>
                                <div className="footNav">
                                    <NavLink className="pr-3" to="/terms">
                                        {t('terms')}
                                    </NavLink>
                                    |
                                    <NavLink className="px-3" to="/policy">
                                        {t('privacy_policy')}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </footer>
    )
}

export default Footer
