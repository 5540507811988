import React, { useContext, useState, useEffect } from 'react'
import { Divider, ListItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import './Dashboard.css'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { handleRedirectInternal } from '@/product/common/components'
import AuthContext from '@/product/context/auth/authContext'
import AuctionContext from '@/product/context/seller/auction/auctionContext'
import ProductContext from '@/product/context/product/productContext'
import CustomCommonContext from '@/custom/context/common/commonContext'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}))

const SideNav = () => {
    const classes = useStyles()
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const { t } = useTranslation()
    const { user, isAuthenticated } = authContext
    const { all_lot_count } = useContext(AuctionContext)
    const [unSoldLotCount, setUnsoldLotCount] = useState(0)
    const {
        all_offers,
        getAllAdminOffers,
        search_alldashboardproducts,
        getAllDashboardProducts,
        responseStatus: responseStatusProduct,
    } = productContext
    const customCommonContext = useContext(CustomCommonContext)
    const { setProfilePopup } = customCommonContext

    const [expanded, setExpanded] = useState('')

    const handleChange = (panel) => {
        const sellerInsidePannel = ['seller/auction', 'seller/buynow', 'payments', 'invoiceseller']

        const buyerInsidePannel = ['auctions']
        if (expanded === panel) {
            if (sellerInsidePannel.includes(panel)) {
                setExpanded('seller')
            } else if (buyerInsidePannel.includes(panel)) {
                setExpanded('compras')
            } else {
                setExpanded('')
            }
        } else {
            setExpanded(panel)
        }
    }

    const {
        all_bid_lot_count,
        getAllMainLoatCount,
        responseStatus: responseStatusSeller,
    } = useContext(AuctionContext)
    const [outbidCounts, setOutbidCounts] = useState()
    const [winningCounts, setwinningCounts] = useState()
    const [makeOfferCount, setMakeOfferCount] = useState()
    const filterBids = {
        category: {
            value: [],
            type: 'array',
            field: 'it.categoryTypeId',
        },
        condition: {
            value: [],
            type: 'array',
            field: 'it.conditionTypeId',
        },
        location: {
            value: [],
            type: 'array',
            field: 'p.location_id',
        },
        price: {
            value: '',
            type: 'greaterequal',
            field: 'p.wprice',
        },
        searchbar: {
            value: '',
            type: 'like',
            field: 'p.title,p.desc_proc',
        },
        auctionid: {
            value: [],
            type: 'array',
            field: 'p.auctionid',
        },
    }

    const makeOffer = {
        searchterm: '',
        // page: 1,
        // limit: 20,
        order: '',
        orderby: '',
        filters: {
            project_id: {
                value: 0,
                type: 'in',
                field: 'mot.project_id',
            },
            project_status: {
                value: 'open',
                type: 'in',
                field: 'p.market_status',
            },
            status: {
                value: '0',
                type: 'in',
                field: 'mot.status',
            },
            email: {
                value: '',
                type: 'like',
                field: 'u.email',
            },
            first_name: {
                value: '',
                type: 'like',
                field: 'u.first_name',
            },
            last_name: {
                value: '',
                type: 'like',
                field: 'u.last_name',
            },
        },
    }
    useEffect(() => {
        if (isAuthenticated) {
            getAllAdminOffers(makeOffer)
        }
    }, [isAuthenticated])
    useEffect(() => {
        if (
            isAuthenticated &&
            expanded === 'auctions' &&
            history.location.pathname !== '/auctions/outbid' &&
            history.location.pathname !== '/auctions/winning'
        ) {
            let initialOutbidValues = {
                limit: 10,
                page: 1,
                orderby: 'p.date_closed,desc',
                order: '',
                auctionView: 'Grid',
                filter: filterBids,
                action: 'outbid',
            }

            let initialWonbidValues = {
                limit: 10,
                page: 1,
                orderby: 'p.date_closed,desc',
                order: '',
                auctionView: 'Grid',
                filter: filterBids,
                action: 'winning',
            }

            getAllDashboardProducts(initialOutbidValues, 'outbidauction')
            getAllDashboardProducts(initialWonbidValues, 'winningauction')
        }
    }, [isAuthenticated, expanded])

    useEffect(() => {
        setMakeOfferCount(all_offers?.totalRecords)
    }, [all_offers])

    useEffect(() => {
        if (search_alldashboardproducts.from === 'outbidauction') {
            setOutbidCounts(search_alldashboardproducts.totalRecords)
        }
        if (search_alldashboardproducts.from === 'winningauction') {
            setwinningCounts(search_alldashboardproducts.totalRecords)
        }
    }, [search_alldashboardproducts])
    useEffect(() => {
        if (all_lot_count) {
            if (all_lot_count.from == 'lotcount') {
                setUnsoldLotCount(all_lot_count?.unsold_count)
            }
        }
    }, [all_lot_count])

    useEffect(() => {
        const path = location.pathname.split('/')
        console.log(path, 'pathNav')
        if (path[1]) {
            if (path[1] == 'seller') setExpanded(path[1] + '/' + path[2])
            else if (path[1] == 'payments') setExpanded(path[1] + '/' + path[2])
            else setExpanded(path[1])
        }
    }, [location.pathname])

    const vendorState = ['seller', 'payments', 'invoiceseller']
    const comprasState = ['auctions', 'documents', 'alloffers', 'compras']

    return (
        <div className="sideNav">
            <div className="userAvatarProfile">
                <span className="userInitials large">
                    {user?.first_name?.charAt(0)}
                    {user?.last_name?.charAt(0)}{' '}
                </span>
                <h4>
                    {'Hello'} {user?.first_name !== 'null' ? user?.first_name : user?.companyname}
                </h4>
            </div>
            <ul>
                <ListItem button onClick={() => handleChange('account')} id="sidebar_my_account">
                    <div
                        className={`d-flex justify-content-between align-items-center w-100 test ${
                            (expanded === 'account' || expanded === 'cuenta') && 'activeDropdown'
                        }`}
                    >
                        <span className="d-flex">
                            <span className="material-icons">person</span>
                            {t('my_account')}
                        </span>
                        {expanded === 'account' || expanded === 'cuenta' ? (
                            <ExpandLess />
                        ) : (
                            <ExpandMore />
                        )}
                    </div>
                </ListItem>
                <Collapse
                    in={expanded === 'account' || expanded === 'cuenta'}
                    timeout="auto"
                    unmountOnExit
                >
                    <List component="div" disablePadding>
                        <ListItem
                            button
                            onClick={() => {
                                setProfilePopup(true)
                            }}
                        >
                            <NavLink
                                activeClassName="active"
                                to="/cuenta/perfil"
                                id="sidebar_profile"
                            >
                                {t('profile')}
                            </NavLink>
                        </ListItem>
                        <ListItem button>
                            <NavLink
                                activeClassName="active"
                                to="/account/change"
                                id="sidebar_change_password"
                            >
                                {t('change_password')}
                            </NavLink>
                        </ListItem>
                        {user?.admin ? null : (
                            <>
                                <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/account/notifications"
                                        id="sidebar_my_notifications"
                                    >
                                        {t('my_notifications')}
                                    </NavLink>
                                </ListItem>
                            </>
                        )}
                    </List>
                </Collapse>

                <>
                    {/* seller MenuList */}
                    <ListItem button onClick={() => handleChange('seller')}>
                        <div
                            className={`d-flex justify-content-between align-items-center w-100 test ${
                                vendorState.some((str) => expanded.includes(str)) &&
                                'activeDropdown'
                            }`}
                        >
                            <span className="d-flex">
                                <span className="material-icons gavel">gavel</span>
                                Ventas
                            </span>
                            {vendorState.some((str) => expanded.includes(str)) ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </div>
                    </ListItem>
                    <Collapse
                        in={vendorState.some((str) => expanded.includes(str))}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItem button onClick={() => handleChange('seller/auction')}>
                                <div
                                    className={`d-flex justify-content-between align-items-center w-100 test ${
                                        expanded == 'seller/auction' && 'activeDropdown'
                                    }`}
                                >
                                    <span className="d-flex">
                                        <span className="material-icons gavel">gavel</span>
                                        {t('my_auctions')}
                                    </span>
                                    {expanded === 'seller/auction' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </div>
                            </ListItem>
                            <Collapse
                                in={expanded === 'seller/auction'}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/auction/open"
                                            id="sidebar_active"
                                        >
                                            {t('active')}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/auction/sold"
                                            id="sidebar_sold"
                                        >
                                            {t('sold')}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/auction/unsold"
                                            id="sidebar_unsold"
                                        >
                                            {t('unsold')}
                                            {unSoldLotCount > 0 && (
                                                <span className="badgeCount">{unSoldLotCount}</span>
                                            )}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/auction/draft"
                                            id="sidebar_draft"
                                        >
                                            {t('draft')}
                                        </NavLink>
                                    </ListItem>
                                    {/* <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/auction/relisted"
                                            id="sidebar_relisted"
                                        >
                                            {t('relisted')}
                                        </NavLink>
                                    </ListItem> */}
                                </List>
                            </Collapse>

                            <ListItem
                                button
                                onClick={() => {
                                    handleChange('seller/buynow')
                                    getAllAdminOffers(makeOffer)
                                }}
                                id="sidebar_my_direct_sale"
                            >
                                <div
                                    className={`d-flex justify-content-between align-items-center w-100 test ${
                                        expanded === 'seller/buynow' && 'activeDropdown'
                                    }`}
                                >
                                    <span className="d-flex">
                                        <span className="material-icons gavel">gavel</span>
                                        Mis Ventas
                                    </span>
                                    {expanded === 'seller/buynow' ? <ExpandLess /> : <ExpandMore />}
                                </div>
                            </ListItem>
                            <Collapse
                                in={expanded === 'seller/buynow'}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/buynow/open"
                                            id="sidebar_active"
                                        >
                                            {t('active')}
                                            {makeOfferCount > 0 && (
                                                <span className="badgeCount">{makeOfferCount}</span>
                                            )}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button onClick={() => getAllAdminOffers(makeOffer)}>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/buynow/sold"
                                            id="sidebar_sold"
                                        >
                                            {t('buynow_solds')}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button onClick={() => getAllAdminOffers(makeOffer)}>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/buynow/unsold"
                                            id="sidebar_unsold"
                                        >
                                            {t('buynow_unsolds')}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button onClick={() => getAllAdminOffers(makeOffer)}>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/buynow/draft"
                                            id="sidebar_draft"
                                        >
                                            {t('draft')}
                                        </NavLink>
                                    </ListItem>
                                    {/* <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/buynow/relisted"
                                            id="sidebar_relisted"
                                        >
                                            {t('relisted')}
                                        </NavLink>
                                    </ListItem> */}
                                </List>
                            </Collapse>

                            <ListItem
                                button
                                onClick={() => handleChange('payments')}
                                id="subscription"
                            >
                                <div
                                    className={`d-flex justify-content-between align-items-center w-100 test ${
                                        expanded.includes('payments') && 'activeDropdown'
                                    }`}
                                >
                                    <span className="d-flex">
                                        <span className="material-icons">credit_card</span>
                                        {t('my_subscription')}
                                    </span>
                                    {expanded.includes('payments') ||
                                    expanded === 'seller/payments' ? (
                                        <ExpandLess />
                                    ) : (
                                        <ExpandMore />
                                    )}
                                </div>
                            </ListItem>
                            <Collapse
                                in={expanded.includes('payments') || expanded === 'seller/payments'}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/payments/viewplans"
                                            id="sidebar_plan_view"
                                        >
                                            Ver planes
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/payments/subscription"
                                            id="sidebar_plan"
                                        >
                                            {t('my_subscription')}
                                            {/* Mis soucripciones */}
                                        </NavLink>
                                    </ListItem>

                                    {/* <ListItem button>
                                    <NavLink activeClassName="active" to="/payments/transactions">
                                        Transactions
                                    </NavLink>
                                </ListItem> */}

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/payments/saved_cards"
                                            id="sidebar_card_details"
                                        >
                                            {t('card_details')}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/seller/payments/mysubcriptionlist"
                                        >
                                            {/* <span className="material-icons">receipt</span> */}
                                            {t('Mysubscripton history')}
                                        </NavLink>
                                    </ListItem>

                                    {/* <ListItem button>
                                    <NavLink
                                        activeClassName="active"
                                        to="/seller/payments/bank_details"
                                    >
                                        Bank Details
                                    </NavLink>
                                </ListItem> */}
                                </List>
                            </Collapse>

                            <ListItem
                                button
                                onClick={() => handleChange('invoiceseller')}
                                id="sidebar_my_documents"
                            >
                                <div
                                    className={`d-flex justify-content-between align-items-center w-100 test ${
                                        expanded === 'invoiceseller' && 'activeDropdown'
                                    }`}
                                >
                                    <span className="d-flex">
                                        <span className="material-icons">receipt</span>
                                        {t('my_documents')}
                                    </span>
                                    {expanded === 'invoiceseller' ? <ExpandLess /> : <ExpandMore />}
                                </div>
                            </ListItem>
                            <Collapse
                                in={expanded === 'invoiceseller'}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/invoiceseller/all"
                                            id="sidebar_invoice_all"
                                        >
                                            {t('All')}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/invoiceseller/paid"
                                            id="sidebar_invoice_paid"
                                        >
                                            {t('paid')}
                                        </NavLink>
                                    </ListItem>

                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/invoiceseller/unpaid"
                                            id="sidebar_invoice_unpaid"
                                        >
                                            Pendiente de pago
                                            {/* {t('unpaid')} */}
                                        </NavLink>
                                    </ListItem>
                                </List>
                            </Collapse>

                            {/* <ListItem button>
                                <NavLink activeClassName="active" to="/seller/reports">
                                    <span className="material-icons">description</span>
                                    {t('reports')}
                                </NavLink>
                            </ListItem> */}
                        </List>
                    </Collapse>
                    {/* buyer MenuList */}
                    <ListItem button onClick={() => handleChange('compras')}>
                        <div
                            className={`d-flex justify-content-between align-items-center w-100 test ${
                                comprasState.some((str) => expanded.includes(str)) &&
                                'activeDropdown'
                            }`}
                        >
                            <span className="d-flex">
                                <span className="material-icons">storefront</span>
                                Compras
                            </span>
                            {comprasState.some((str) => expanded.includes(str)) ? (
                                <ExpandLess />
                            ) : (
                                <ExpandMore />
                            )}
                        </div>
                    </ListItem>
                    <Collapse
                        in={comprasState.some((str) => expanded.includes(str))}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            <ListItem
                                button
                                onClick={() => handleChange('auctions')}
                                id="sidebar_my_bids"
                            >
                                <div
                                    className={`d-flex justify-content-between align-items-center w-100 test ${
                                        expanded === 'auctions' && 'activeDropdown'
                                    }`}
                                >
                                    <span className="d-flex">
                                        <span className="material-icons gavel">gavel</span>
                                        {t('my_bids')}
                                    </span>
                                    {expanded === 'auctions' ? <ExpandLess /> : <ExpandMore />}
                                </div>
                            </ListItem>

                            <Collapse in={expanded === 'auctions'} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/auctions/outbid"
                                            id="sidebar_currently_outbid"
                                        >
                                            {t('currently_outbid')}
                                            {outbidCounts > 0 && (
                                                <span className="badgeCount">{outbidCounts}</span>
                                            )}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/auctions/winning"
                                            id="sidebar_winning_bids"
                                        >
                                            {t('temperverily winning')}
                                            {winningCounts > 0 && (
                                                <span className="badgeCount">{winningCounts}</span>
                                            )}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/auctions/won"
                                            id="sidebar_won"
                                        >
                                            {t('Adjudicados')}
                                        </NavLink>
                                    </ListItem>
                                    <ListItem button>
                                        <NavLink
                                            activeClassName="active"
                                            to="/auctions/lost"
                                            id="sidebar_all_bids"
                                        >
                                            {/* {t('All')} */}
                                            {t('losts')}
                                        </NavLink>
                                    </ListItem>
                                </List>
                            </Collapse>

                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/alloffers"
                                    id="sidebar_all_offers"
                                >
                                    {/* <img src="/assets/svg/priority_high.svg" alt="" /> */}
                                    <span className="material-icons-outlined">touch_app</span>
                                    {t('all_offers')}
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/documents/all"
                                    id="sidebar_my_documents"
                                >
                                    <span className="material-icons">receipt</span>
                                    {t('my_documents')}
                                </NavLink>
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button>
                        <NavLink
                            activeClassName="active"
                            to="/watchlist/watchlist"
                            id="sidebar_my_favourites"
                        >
                            <span className="material-icons">favorite</span>
                            {t('my_favourites')}
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/saved_search" id="sidebar_my_alerts">
                            <span className="material-icons">bookmark</span>
                            {t('my_alerts')}
                        </NavLink>
                    </ListItem>
                </>
                <>
                    {/* <ListItem
                        button
                        onClick={() => handleChange('seller/lots')}
                        id="sidebar_my_lots"
                    >
                        <div
                            className={`d-flex justify-content-between align-items-center w-100 test ${
                                expanded === 'seller/lots' && 'activeDropdown'
                            }`}
                        >
                            <span className="d-flex">
                                <span className="material-icons gavel">gavel</span>
                                {t('my_lots')}
                            </span>
                            {expanded === 'seller/lots' ? <ExpandLess /> : <ExpandMore />}
                        </div>
                    </ListItem>
                    <Collapse in={expanded === 'seller/lots'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/seller/lots/open"
                                    id="sidebar_active"
                                >
                                    {t('active')}
                                </NavLink>
                            </ListItem>

                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/seller/lots/sold"
                                    id="sidebar_sold"
                                >
                                    {t('sold')}
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/seller/lots/unsold"
                                    id="sidebar_unsold"
                                >
                                    {t('unsold')}
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/seller/lots/draft"
                                    id="sidebar_draft"
                                >
                                    {t('draft')}
                                </NavLink>
                            </ListItem>
                            <ListItem button>
                                <NavLink
                                    activeClassName="active"
                                    to="/seller/lots/relisted"
                                    id="sidebar_relisted"
                                >
                                    {t('relisted')}
                                </NavLink>
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/templates">
                            <span className="material-icons">table_chart</span>
                            Auction Templates
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/product/templates">
                            <span className="material-icons">table_chart</span>
                            Listing Templates
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/seller/organization">
                            <span className="material-icons">corporate_fare</span>
                            Organization
                        </NavLink>
                    </ListItem> 

                    <ListItem button>
                        <NavLink activeClassName="active" to="/purchases/buynow">
                            <span className="material-icons">shopping_basket</span> {'My Purchases'}
                        </NavLink>
                    </ListItem>
                    <ListItem button onClick={() => handleChange('payments')}>
                        <div
                            className={`d-flex justify-content-between align-items-center w-100 test ${
                                expanded === 'payments' && 'activeDropdown'
                            }`}
                        >
                            <span className="d-flex">
                                <span className="material-icons">credit_card</span>
                                My Payments
                            </span>
                            {expanded === 'payments' ? <ExpandLess /> : <ExpandMore />}
                        </div>
                    </ListItem>
                    <Collapse in={expanded === 'payments'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button>
                                <NavLink activeClassName="active" to="/payments/transactions">
                                    Transactions
                                </NavLink>
                            </ListItem>

                            <ListItem button>
                                <NavLink activeClassName="active" to="/payments/saved_cards">
                                    Card Details
                                </NavLink>
                            </ListItem>

                            <ListItem button>
                                <NavLink activeClassName="active" to="/payments/bank_details">
                                    Bank Details
                                </NavLink>
                            </ListItem>

                            <ListItem button>
                                <NavLink activeClassName="active" to="/payments/subscription">
                                    My Subscription
                                </NavLink>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem button>
                        <NavLink activeClassName="active" to="/wallet">
                            <span className="material-icons">account_balance_wallet</span>
                            My Wallet
                        </NavLink>
                    </ListItem>
                    <ListItem button onClick={() => handleChange('invoices')}>
                        <div
                            className={`d-flex justify-content-between align-items-center w-100 test ${
                                expanded === 'invoices' && 'activeDropdown'
                            }`}
                        >
                            <span className="d-flex">
                                <span className="material-icons">receipt</span>
                                My Invoices
                            </span>
                            {expanded === 'invoices' ? <ExpandLess /> : <ExpandMore />}
                        </div>
                    </ListItem>
                    <Collapse in={expanded === 'invoices'} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button>
                                <NavLink activeClassName="active" to="/invoices/all">
                                    All
                                </NavLink>
                            </ListItem>

                            <ListItem button>
                                <NavLink activeClassName="active" to="/invoices/paid">
                                    Paid
                                </NavLink>
                            </ListItem>

                            <ListItem button>
                                <NavLink activeClassName="active" to="/invoices/partial">
                                    Unpaid
                                </NavLink>
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem button>
                        <NavLink activeClassName="active" to="/deposits/bid_deposit">
                            <span className="material-icons">how_to_vote</span> {'Deposits'}
                        </NavLink>
                    </ListItem>*/}
                </>
            </ul>
        </div>
    )
}

export default SideNav
