import React, { useContext, useEffect, useState } from 'react'
import { converDate } from '../../../../common/components'
import PrimaryButton from '../../../atoms/PrimaryButton'
import { Button } from '@material-ui/core'
import CustomDialog from '../../../organisms/Dialog/index'
import StripeCardContext from '../../../../context/stripe/card/cardContext'
import StripeCustomerContext from '../../../../context/stripe/customer/customerContext'
import AuthContext from '../../../../context/auth/authContext'
import ProductCommonContext from '../../../../context/common/commonContext'
import StripeBankContext from '../../../../context/stripe/bank/bankContext'
import CustomLoader from '../../../molecules/Loaders/index'
import AlertContext from '../../../../context/alert/alertContext'
import PaymentComponent from '../../../species/components/checkout/PaymentComponent'
import UserContext from '../../../../context/user/userContext'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
const MyPaymentComponent = (props) => {
    const authContext = useContext(AuthContext)
    const productCommonContext = useContext(ProductCommonContext)
    const alertContext = useContext(AlertContext)
    const { t } = useTranslation()
    const { setAlert } = alertContext
    const { user, isAuthenticated, loadUser } = authContext
    const { updateProfile, responseStatus: responseStatusUser } = useContext(UserContext)

    // prettier-ignore
    const { getPreviousData, clearPreviousData, previous_data, allCountries, allStates } =
        productCommonContext
    const {
        get_all_card_details,
        createStripeCard,
        createCustomStripeCard,
        createStripeCardWithCustomer,
        getAllStripeCards,
        deleteStripeCard,
        updateStripeCard,
        getAllCustomStripeCards,
        deleteCustomStripeCard,
        updateCustomStripeCard,
        responseStatus: responseStatusCard,
        clearResponse,
    } = useContext(StripeCardContext)

    const {
        createStripeBank,
        deleteStripeBank,
        updateStripeBank,
        verifyStripeBank,
        get_all_bank_details,
        getAllStripeBanks,
        getAllCustomStripeBanks,
        verifyCustomStripeBank,
        responseStatus: responseStatusBank,
        clearResponse: clearResponseBank,
    } = useContext(StripeBankContext)
    const {
        updateStripeCustomer,
        updateCustomStripeCustomer,
        getStripeCustomer,
        getCustomStripeCustomer,
        customer_details,
        responseStatus: customerResponseStatus,
        createStripeCustomer,
        createCustomStripeCustomer,
    } = useContext(StripeCustomerContext)

    const [isLoading, setIsLoading] = useState(false)
    const [changeStatus, setChangeStatus] = useState(false)
    const [userStripeId, setUserStripeId] = useState('')
    const [formikValues, setFormikValues] = useState([])
    const [allCountryValue, setAllCountryValue] = useState([])
    const [allStateValue, setAllStateValue] = useState([])
    const [customAlert, setCustomAlert] = useState({
        type: 'info',
        icon: '',
        label: 'US only for now',
    })

    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
        props.function(false, 'new', null)
    }

    const [reload, setReload] = useState(false)

    const getStripeCustomerDetails = () => {
        if (user && user.stripe_id) {
            if (props.from === 'card') {
                getStripeCustomer({
                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                    customer_id: user.stripe_id,
                })
            } else if (props.from === 'custom_card') {
                getCustomStripeCustomer({
                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                    customer_id: user.stripe_id,
                })
            }
        }
    }

    const getStripeCards = () => {
        // console.log('user!@#', user)
        if (user && user.stripe_id) {
            if (props.from === 'card') {
                getAllStripeCards({
                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                    customer_id: user.stripe_id,
                    object: 'card',
                })
            } else if (props.from === 'custom_card') {
                getAllCustomStripeCards({
                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                    customer_id: user.stripe_id,
                    object: 'card',
                })
            }
        }
        props.function(false, 'new', null)
    }

    const getStripeBanks = () => {
        if (props.from === 'bank') {
            getAllStripeBanks({
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                customer_id: user.stripe_id,
                object: 'bank_account',
            })
        } else if (props.from === 'custom_bank') {
            getAllCustomStripeBanks({
                account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                customer_id: user.stripe_id,
                object: 'bank_account',
            })
        }
        props.function(false, 'new', null)
    }

    useEffect(() => {
        if (isAuthenticated) {
            if (user) {
                if (user.id) {
                    if (user.stripe_id) {
                        if (props.from === 'card' || props.from === 'custom_card') {
                            getStripeCards()
                        } else if (props.from === 'bank' || props.from === 'custom_bank') {
                            getStripeBanks()
                        }
                        getStripeCustomerDetails()
                    } else {
                        if (props.from === 'custom_card') {
                            createCustomStripeCustomer(
                                {
                                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                                    description: 'TEST CUSTOMER',
                                    email: user.email,
                                    name: user.first_name + ' ' + user.last_name,
                                },
                                true,
                            )
                        } else {
                            createStripeCustomer(
                                {
                                    account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                                    description: 'TEST CUSTOMER',
                                    email: user.email,
                                    name: user.first_name + ' ' + user.last_name,
                                },
                                true,
                            )
                        }
                    }
                }
            }
        }
    }, [user, isAuthenticated])

    useEffect(() => {
        if (responseStatusCard) {
            if (responseStatusCard.status === 'success') {
                if (props.from === 'custom_bank') {
                    getStripeBanks()
                } else {
                    getStripeCards()
                }
            }
        }
    }, [responseStatusCard])

    useEffect(() => {
        setIsLoading(false)
    }, [responseStatusBank])

    const deleteHandler = (e) => {
        e.preventDefault()
        if (props.data.data) {
            if (props.from === 'card') {
                if (props.data.status === 'delete') {
                    deleteStripeCard({
                        account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                        customer_id: user.stripe_id,
                        source_id: props.data.data.id,
                    })
                    setChangeStatus(false)
                    props.function(false, 'new', null)
                } else if (props.data.status === 'default') {
                    updateStripeCustomer({
                        account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                        customer_id: user.stripe_id,
                        default_source: props.data.data.id,
                    })
                    setChangeStatus(false)
                    props.function(false, 'new', null)
                }
            } else if (props.from === 'custom_card' || props.from === 'custom_bank') {
                if (props.data.status === 'delete') {
                    deleteCustomStripeCard({
                        account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                        customer_id: user.stripe_id,
                        source_id: props.data.data.id,
                    })
                    setChangeStatus(false)
                    props.function(false, 'new', null)
                } else if (props.data.status === 'default') {
                    updateCustomStripeCustomer({
                        account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                        customer_id: user.stripe_id,
                        default_source: props.data.data.id,
                    })
                    setChangeStatus(false)
                    props.function(false, 'new', null)
                }
            } else if (props.from === 'bank') {
                if (props.data.status === 'delete') {
                    deleteStripeBank({
                        account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
                        customer_id: user.stripe_id,
                        source_id: props.data.data.id,
                    })
                } else if (props.data.status === 'update') {
                    setReload(!reload)
                }
            }
        }
        setIsLoading(false)
    }

    // useEffect(() => {
    //     if (previous_data) {
    //         if (previous_data.from === 'cardcheck') {
    //             if (previous_data.records.length) {
    //                 setAlert(
    //                     'Removal of Card/ACH is permitted only after 6 weeks from the last bid',
    //                     'error',
    //                 )
    //                 //formikSelection.values.data = ''
    //                // formikSelection.values.status = ''
    //                 clearPreviousData()
    //                 props.function(false, 'new', null)
    //             } else if (formikSelection.values.status) {
    //                 setChangeStatus(true)
    //             }
    //             setIsLoading(false)
    //         }
    //     }
    // }, [previous_data])

    useEffect(() => {
        if (props.data.data) {
            if (props.data.status === 'delete') {
                if (props.allCards && props.allCards.length > 1) {
                    setChangeStatus(true)
                } else {
                    var sixWeekAgo = new Date()
                    sixWeekAgo.setDate(sixWeekAgo.getDate() - 42)

                    getPreviousData(
                        {
                            page: 1,
                            limit: 20,
                            order: '',
                            orderby: '',
                            primaryTable: 'bids',
                            getUser: true,
                            filters: {
                                datebefore: {
                                    value: converDate(sixWeekAgo),
                                    type: 'dategreaterequal',
                                    field: 'b.created_at',
                                },
                            },
                        },
                        'cardcheck',
                    )
                }
            } else if (props.data.status === 'default') {
                setChangeStatus(true)
            } else if (props.data.status === 'update') {
                formikValues.source_id = props.data.data.id
                formikValues.customer_id = props.data.data.customer
                formikValues.exp_month = ('0' + props.data.data.exp_month).slice(-2)
                formikValues.exp_year = props.data.data.exp_year
                setReload(!reload)
            }
            setIsLoading(false)
        }
    }, [props.data.data])

    useEffect(() => {
        if (responseStatusBank) {
            if (
                responseStatusBank.from === 'bank_source_create' ||
                responseStatusBank.from === 'bank_source_update' ||
                responseStatusBank.from === 'bank_source_delete' ||
                responseStatusBank.from === 'BANK_CUSTOM_SOURCE_LIST' ||
                responseStatusBank.from === 'BANK_CUSTOM_SOURCE_VERIFY'
            ) {
                if (responseStatusBank.status === 'success') {
                    getStripeBanks()
                }
            }
            setIsLoading(false)
        }
        return () => {
            clearResponseBank()
        }
    }, [responseStatusBank])

    const creditCardYearOptions = []
    let date = new Date()
    let currentYear = date.getFullYear()
    let upToYear = parseInt(currentYear) + 25
    for (let year = parseInt(currentYear); year < parseInt(upToYear); year++) {
        creditCardYearOptions.push({
            value: year,
            show: year,
        })
    }
    const creditCardMonthOptions = []
    for (let month = parseInt(1); month <= parseInt(12); month++) {
        let monthValue = ('0' + month).slice(-2)
        creditCardMonthOptions.push({
            value: monthValue,
            show: monthValue,
        })
    }

    useEffect(() => {
        setAllCountryValue(allCountries)
        setAllStateValue(allStates)
    }, [allStates, allCountries])

    const validationupdateCard = Yup.object({
        exp_month: Yup.string().required(t('Required!')),
        exp_year: Yup.string().required(t('Required!')),
    })

    const validationCard = Yup.object({
        card_number: Yup.string()
            .min(12, t('Invalid credit card number!'))
            .max(18, t('Invalid credit card number!'))
            .required(t('Required!')),
        card_name: Yup.string()
            .trim()
            .matches(
                /^[a-zA-Z\s ."'\-]*$/g,
                t('The special characters and numbers are not allowed!'),
            )
            .required(t('Required!')),
        card_exp_month: Yup.string().required(t('Required!')),
        card_exp_year: Yup.string().required(t('Required!')),
        card_cvc: Yup.string().matches('^[0-9]{3,4}$', 'Invalid CVV!').required(t('Required!')),
        card_address_line1: Yup.string().required(t('Required!')),
        card_address_line2: Yup.string(),
        card_address_city: Yup.string().required(t('Required!')),
        card_address_state: Yup.string().required(t('Required!')),
        card_address_country: Yup.string().required(t('Required!')),
        card_address_zip: Yup.string().required(t('Required!')),
        // phone: Yup.string().matches(phonenumber, 'Phone number is not valid'),
    })

    const CardinitialValues = {
        account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
        customer_id: user ? user.stripe_id : '',
        card_number: '',
        card_name: '',
        card_exp_month: '',
        card_exp_year: '',
        card_cvc: '',
        cvv: '',
        card_address_line1: '',
        card_address_line2: '',
        card_address_city: '',
        card_address_state: '',
        card_address_zip: '',
        card_address_country: global.defaultCountry,
        is_primary: '',
        user_id: '',
        id: '',
        card_token: true,
    }
    const updateCardInitialValues = {
        account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
        customer_id: user ? user.stripe_id : '',
        source_id: '',
        exp_month: '',
        exp_year: '',
    }

    const cardUpdate = {
        data: [
            {
                label: 'Expiry Month',
                placeholder: 'MM',
                class: 'col-6 ',
                type: 'select',
                name: 'exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: 'Expiry Year',
                placeholder: 'YYYY',
                class: 'col-6 ',
                type: 'select',
                name: 'exp_year',
                options: creditCardYearOptions,
            },
        ],
    }

    const cardDetails = {
        data: [
            {
                label: t('Card Holder Name'),
                type: 'text',
                placeholder: t('Enter your card holder name'),
                class: 'col-12 col-md-6',
                name: 'card_name',
            },
            {
                label: t('Card Number'),
                type: 'number',
                placeholder: t('Enter your card number'),
                class: 'col-12 col-md-6',
                name: 'card_number',
            },
            {
                label: t('Expiry Month'),
                placeholder: 'MM',
                class: 'col-12 col-md-6',
                type: 'select',
                name: 'card_exp_month',
                options: creditCardMonthOptions,
            },
            {
                label: t('Expiry Year'),
                placeholder: 'YYYY',
                class: 'col-12 col-md-6',
                type: 'select',
                name: 'card_exp_year',
                options: creditCardYearOptions,
            },
            {
                label: 'CVV',
                type: 'text',
                placeholder: t('Enter your CVV'),
                class: 'col-12 col-md-6',
                name: 'card_cvc',
            },
            {
                label: t('Billing address line 1'),
                type: 'text',
                placeholder: t('Enter billing address line1'),
                class: 'col-12 col-md-6',
                name: 'card_address_line1',
            },
            {
                label: t('Billing address line 2'),
                type: 'text',
                placeholder: t('Enter billing address line2'),
                class: 'col-12 col-md-6',
                name: 'card_address_line2',
            },
            {
                label: t('Billing city'),
                type: 'text',
                placeholder: t('Enter billing address city'),
                class: 'col-12 col-md-6 billingAdd',
                name: 'card_address_city',
            },
            // {
            //     label: 'Card address state',
            //     type: 'text',
            //     placeholder: 'Enter card address state',
            //     class: 'col-12',
            //     name: 'card_address_state',
            // },
            // {
            //     label: 'Card address country',
            //     type: 'text',
            //     placeholder: 'Enter card address country',
            //     class: 'col-12',
            //     name: 'card_address_country',
            // },
            {
                label: t('Billing country'),
                placeholder: t('Select billing address country'),
                class: 'col-12 col-md-6',
                type: 'select',
                options: allCountryValue
                    .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.isoCode
                        return busCategoryChanged
                    }),
                name: 'card_address_country',
            },
            {
                label: t('Billing state'),
                type: 'select',
                placeholder: t('Select billing address state'),
                class: 'col-12 col-md-6',
                options: allStateValue
                    .filter(
                        (state) =>
                            (formikValues.card_address_country
                                ? formikValues.card_address_country.indexOf(state.countryCode) !==
                                  -1
                                : global.defaultCountry.indexOf(state.countryCode) !== -1) &&
                            global.ignoreStates.indexOf(state.isoCode) === -1,
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.isoCode
                        return busCategoryChanged
                    }),
                name: 'card_address_state',
            },

            {
                label: t('Billing zip/postal code'),
                type: 'text',
                placeholder: t('Enter billing zip/postal code'),
                class: 'col-12 col-md-6',
                name: 'card_address_zip',
            },
        ],
    }
    const accountHolderType = [
        { value: 'individual', show: 'Individual' },
        { value: 'company', show: 'Company' },
    ]

    const validationBank = Yup.object({
        bank_account_holder_name: Yup.string('Must be string')
            .trim()
            .matches(/^[a-zA-Z\s ."'\-]*$/g, 'The special characters and numbers are not allowed!')
            .min(2, 'Invalid Name!')
            .max(50, 'Invalid Name!')
            .required('Required!'),
        bank_account_number: Yup.number('Number Required')
            .min(2, 'Invalid Account Number')
            .positive('Positive Numbers Only Allowed')
            .required('Required!'),
        bank_account_holder_type: Yup.string().required('Required!'),
        bank_routing_number: Yup.number('Numbers Only Allowed')
            .positive('Positive Numbers Only Allowed')
            .required('Required!'),
    })

    const initialValues = {
        account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
        customer_id: user ? user.stripe_id : '',
        bank_country: 'us',
        bank_currency: 'usd',
        bank_account_holder_name: '',
        bank_account_holder_type: '',
        bank_routing_number: '',
        bank_account_number: '',
        bank_token: true,
    }

    const validationupdateBank = Yup.object({
        account_holder_name: Yup.string().required('Required!'),
        account_holder_type: Yup.string().required('Required!'),
    })

    const validationVerifyBank = Yup.object({
        amount_1: Yup.number('Numbers only!').required('Required!'),
        amount_2: Yup.number('Numbers only!').required('Required!'),
    })

    const updateInitialValues = {
        account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
        customer_id: '',
        source_id: '',
        account_holder_name: '',
        account_holder_type: '',
    }

    const verifyInitialValues = {
        account_id: process.env.REACT_APP_STRIPE_ACCOUNT,
        customer_id: '',
        source_id: '',
        amount_1: '',
        amount_2: '',
    }

    const bankUpdate = {
        data: [
            {
                label: 'Account holder name',
                type: 'text',
                placeholder: 'Enter your first name',
                class: 'col-12',
                name: 'account_holder_name',
            },
            {
                label: 'Account Type',
                type: 'select',
                options: accountHolderType,
                placeholder: 'Enter account holder type',
                class: 'col-12',
                name: 'bank_account_holder_type',
            },
        ],
    }

    const bankVerify = {
        data: [
            {
                label: 'Enter amount 1',
                type: 'number',
                placeholder: 'Enter credited amount 1',
                class: 'col-12',
                name: 'amount_1',
            },
            {
                label: 'Enter amount 2',
                type: 'text',
                placeholder: 'Enter credited amount 2',
                class: 'col-12',
                name: 'amount_2',
            },
        ],
    }

    const bankDetails = {
        data: [
            {
                label: 'Account Holder Name',
                type: 'text',
                placeholder: 'Enter account holder name',
                class: 'col-12',
                name: 'bank_account_holder_name',
                autoFocus: true,
            },
            {
                label: 'Account Number',
                type: 'string',
                placeholder: 'Enter account number',
                name: 'bank_account_number',
                class: 'col-12',
            },
            {
                label: 'Account Type',
                type: 'select',
                options: accountHolderType,
                placeholder: 'Enter account holder type',
                class: 'col-12',
                name: 'bank_account_holder_type',
            },
            {
                label: 'Routing Number',
                type: 'string',
                placeholder: 'Enter routing number',
                class: 'col-12',
                name: 'bank_routing_number',
            },
        ],
    }

    const additionalfieldUpdate = (payload) => {
        setFormikValues(payload)
        if (props.additionalfieldUpdate) {
            props.additionalfieldUpdate(payload)
        }
    }
    const customLabels = {
        card: {
            new: {
                SubmitBtnLabel: t('submit'),
                BackBtnLabel: t('close'),
                HeadText: t('NEW CREDIT / DEBIT CARD'),
                formFields: cardDetails,
                initialValues: CardinitialValues,
                validationSchema: validationCard,
                ActionFunction: createStripeCard,
            },
            update: {
                SubmitBtnLabel: t('submit'),
                BackBtnLabel: t('close'),
                HeadText: 'UPDATE CARD DETAILS',
                formFields: cardUpdate,
                initialValues: updateCardInitialValues,
                validationSchema: validationupdateCard,
                ActionFunction: updateStripeCard,
            },
        },
        bank: {
            new: {
                SubmitBtnLabel: 'Save',
                BackBtnLabel: 'Close',
                HeadText: 'NEW BANK DETAILS',
                formFields: bankDetails,
                initialValues: initialValues,
                validationSchema: validationBank,
                ActionFunction: createStripeBank,
            },
            update: {
                SubmitBtnLabel: 'Update',
                BackBtnLabel: 'Close',
                HeadText: 'UPDATE BANK DETAILS',
                formFields: bankUpdate,
                initialValues: updateInitialValues,
                validationSchema: validationupdateBank,
                ActionFunction: updateStripeBank,
            },
            verify: {
                SubmitBtnLabel: 'Verify',
                BackBtnLabel: 'Close',
                HeadText: 'Verify BANK ',
                formFields: bankVerify,
                initialValues: verifyInitialValues,
                validationSchema: validationVerifyBank,
                ActionFunction: verifyStripeBank,
            },
        },
        custom_card: {
            new: {
                SubmitBtnLabel: t('submit'),
                BackBtnLabel: t('close'),
                HeadText: t('NEW CREDIT / DEBIT CARD'),
                formFields: cardDetails,
                initialValues: CardinitialValues,
                validationSchema: validationCard,
                ActionFunction: createCustomStripeCard,
            },
            update: {
                SubmitBtnLabel: t('submit'),
                BackBtnLabel: t('close'),
                HeadText: 'UPDATE CARD DETAILS',
                formFields: cardUpdate,
                initialValues: updateCardInitialValues,
                validationSchema: validationupdateCard,
                ActionFunction: createCustomStripeCard,
            },
        },
        custom_bank: {
            new: {
                SubmitBtnLabel: 'Save',
                BackBtnLabel: 'Close',
                HeadText: 'NEW BANK DETAILS',
                formFields: bankDetails,
                initialValues: initialValues,
                validationSchema: validationBank,
                ActionFunction: createCustomStripeCard,
            },
            update: {
                SubmitBtnLabel: 'Update',
                BackBtnLabel: 'Close',
                HeadText: 'UPDATE BANK DETAILS',
                formFields: bankUpdate,
                initialValues: updateInitialValues,
                validationSchema: validationupdateBank,
                ActionFunction: createCustomStripeCard,
            },
            verify: {
                SubmitBtnLabel: 'Verify',
                BackBtnLabel: 'Close',
                HeadText: 'Verify BANK ',
                formFields: bankVerify,
                initialValues: verifyInitialValues,
                validationSchema: validationVerifyBank,
                ActionFunction: verifyCustomStripeBank,
            },
        },
    }

    useEffect(() => {
        if (responseStatusCard) {
            if (responseStatusCard.from === 'card_source_create') {
                if (responseStatusCard.status === 'success') {
                    // cardFormik.resetForm()
                    getStripeCustomerDetails()
                }
            }
            setIsLoading(false)
            clearResponse()
        }
    }, [responseStatusCard])

    useEffect(() => {
        if (
            customerResponseStatus &&
            customerResponseStatus.from == 'CUSTOM_CREATE' &&
            customerResponseStatus.status == 'success'
        ) {
            updateProfile({ stripe_id: customerResponseStatus.data.result.id }, true)
        }
    }, [customerResponseStatus])
    useEffect(() => {
        if (
            customerResponseStatus &&
            customerResponseStatus.from == 'create' &&
            customerResponseStatus.status == 'success'
        ) {
            updateProfile({ stripe_id: customerResponseStatus.data.id }, true)
        }
    }, [customerResponseStatus])

    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'updateProfile' ||
                responseStatusUser.from === 'updatePreference'
            ) {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                }
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        if (customerResponseStatus) {
            if (customerResponseStatus.status === 'success') {
                if (customerResponseStatus.from === 'update') {
                    getStripeCustomerDetails()
                    setIsLoading(false)
                }
            }
        }
    }, [customerResponseStatus])

    useEffect(() => {
        if (user) {
            setUserStripeId(user.stripe_id)
        }
    }, [user])

    return (
        <>
            {isLoading ? <CustomLoader /> : ''}

            {props.data.popup && customLabels[props.from][props.data.status] ? (
                <PaymentComponent
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    formFields={customLabels[props.from][props.data.status].formFields}
                    initialValues={customLabels[props.from][props.data.status].initialValues}
                    validationSchema={customLabels[props.from][props.data.status].validationSchema}
                    from={props.from}
                    function={props.function}
                    data={props.data}
                    additionalfieldUpdate={additionalfieldUpdate}
                    userStripeId={userStripeId}
                    ActionFunction={customLabels[props.from][props.data.status].ActionFunction}
                    SubmitBtnLabel={customLabels[props.from][props.data.status].SubmitBtnLabel}
                    BackBtnLabel={customLabels[props.from][props.data.status].BackBtnLabel}
                    HeadText={customLabels[props.from][props.data.status].HeadText}
                />
            ) : null}

            {props.data.status === 'delete' || props.data.status === 'default' ? (
                <CustomDialog
                    title={'Confirmation'}
                    open={
                        props.data.status === 'delete' || props.data.status === 'default'
                            ? true
                            : false
                    }
                    function={changeChangeStatus}
                >
                    <h5>
                        {props.data.status === 'delete'
                            ? 'Are you sure you want to delete'
                            : props.data.status === 'default'
                            ? 'Are you sure you want to make this default?'
                            : 'Are you sure you want to change the status?'}
                    </h5>
                    <div className="actionWrapper">
                        <Button id="payment_status" onClick={() => changeChangeStatus()}>
                            Cancel
                        </Button>
                        <form onSubmit={deleteHandler} autoComplete="nofill">
                            <PrimaryButton
                                id="payment_submit"
                                type="submit"
                                label="Confirm"
                                disabled={isLoading}
                            />
                        </form>
                    </div>
                </CustomDialog>
            ) : null}
        </>
    )
}

export default MyPaymentComponent
