import React from 'react'
import './Subcription.css'
import CustomDialog from '../../organisms/CustomDialog'
import SubcriptionComponent from './SubcriptionComponent'

const Subcription = (props) => {
    const handleClose = () => {
        props.setViewSubscription(false)
    }

    return (
        <CustomDialog
            className="subscriptionModal"
            function={handleClose}
            open={props.viewSubscription}
            maxWidth="xl"
        >
            <div className="ModalLogoSec">
                <img className="Logo" src="/assets/images/logo.png" alt="Images" />
            </div>
            <SubcriptionComponent />
        </CustomDialog>
    )
}
export default Subcription
