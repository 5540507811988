import React, { useContext, useState, useEffect } from 'react'
import PrimaryButton from '../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../../product/components/organisms/FullScreenPopup'
import AppointmentContext from '../../../product/context/appointment/appointmentContext'
import AuthContext from '../../../product/context/auth/authContext'
import { Button, ListItem } from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import moment from 'moment'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../../product/common/components'
import { dateFormatFrontOnlyDate } from '../../../custom/common/components'
import './AppointmentManage.css'
import Loaders from '../../../product/components/molecules/Loaders'

const today = new Date()
const tomorrow = new Date(today.getTime() + 1 * 24 * 3600000)
const twodays = new Date(today.getTime() + 2 * 24 * 3600000)
const threedays = new Date(today.getTime() + 3 * 24 * 3600000)
const oneweek = new Date(threedays.getTime() + 7 * 24 * 3600000)

const AppointmentManage = (props) => {
    const appointmentContext = useContext(AppointmentContext)
    const { isAuthenticated } = useContext(AuthContext)
    const {
        all_appointments_auction,
        invoice_appointment,
        getAppointmentForInvoice,
        getAllAppointmentsForAuction,
        updateAppointment,
        responseStatus: responseStatusAppointment,
        clearResponse: clearResponseAppointment,
    } = appointmentContext
    const [isLoading, setIsLoading] = useState(false)
    const [allAvailableTime, setAllAvailableTime] = useState([])
    const [customDate, setCustomDate] = useState(false)
    const [dateCalendar, setDateCalendar] = useState(false)
    const history = useHistory()

    const validationArray = Yup.object({
        pickupDay: Yup.string().when(['local_pickup_check'], {
            is: (a) => a === true,
            then: Yup.string().required('Appointment date is required!'),
        }),
        pickupTime: Yup.string().when(['local_pickup_check', 'pickupDay'], {
            is: (a, b) => a === true && b != '',
            then: Yup.string().required('Appointment time is required!'),
        }),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            invoice_id: 0,
            pickupDay: '',
            pickupTime: '',
            auction_id: 0,
            local_pickup_check: false,
        },
        enableReinitialize: true,
        validationSchema: validationArray,
        onSubmit: (values) => {
            setIsLoading(true)
            let appointmentTime = null
            if (values.pickupDay && values.pickupTime) {
                let pickupChanged = values.pickupDay + ' ' + values.pickupTime
                appointmentTime = moment(pickupChanged, 'MM-DD-YYYY HH:mm').format()
            }
            if (appointmentTime) {
                values = {
                    ...values,
                    appointmentTime,
                }
            }
            updateAppointment(values)
        },
    })

    useEffect(() => {
        if (isAuthenticated) {
            getAppointmentForInvoice({
                invoice_id: props.match.params.id,
            })
        }
    }, [props.match.params.id, isAuthenticated])

    useEffect(() => {
        if (invoice_appointment.records && invoice_appointment.records.length > 0) {
            const appointment = invoice_appointment.records[0]
            formik.setValues({
                id: appointment.appointment_id,
                local_pickup_check: appointment.local_pickup_check === 1 ? true : false,
                auction_id: appointment.auction_id,
                invoice_id: appointment.common_invoice,
            })
            getAllAppointmentsForAuction({
                auction_id: appointment.auction_id,
            })
        } else {
            resetFrom()
        }
        setIsLoading(false)
    }, [invoice_appointment])

    const resetFrom = () => {
        formik.values.pickupDay = ''
        formik.values.pickupTime = ''
        formik.handleReset()
        setIsLoading(false)
    }

    const formSubmit = (e) => {
        formik.handleSubmit(e)
        console.log(formik.errors)
    }

    function intervals(startString, endString) {
        const intervalMinutes = invoice_appointment.records?.[0]?.local_pickup_slot_timeframe || 30
        var start = moment(startString, 'YYYY-MM-DD HH:mm')
        var end = moment(endString, 'YYYY-MM-DD HH:mm')
        start.minutes(Math.ceil(start.minutes() / intervalMinutes) * intervalMinutes)
        var result = []
        var current = moment(start)
        while (current < end) {
            result.push({
                value: moment(current).format('HH:mm'),
                show: `${current.format('h:mm a')}`,
            })
            current.add(intervalMinutes, 'minutes')
        }
        return result
    }

    useEffect(() => {
        const selectedDate = moment(formik.values.pickupDay, 'MM-DD-YYYY').format()
        let weekDate = parseInt(moment(selectedDate).format('d'))
        // let validDate = invoice_appointment.records
        //     ?.at(0)
        //     ?.local_pickup_dates?.split(',')
        //     .filter(
        //         (d) =>
        //             new Date(parseInt(d)).toISOString().split('T')[0] ===
        //             moment(formik.values.pickupDay, 'MM-DD-YYYY').format().split('T')[0],
        //     )
        let validDate = invoice_appointment.records
            ?.at(0)
            ?.local_pickup_dates?.split(',')
            .filter(
                (d) => d === moment(formik.values.pickupDay, 'MM-DD-YYYY').format().split('T')[0],
            )
        if (weekDate !== null && validDate && validDate.length > 0) {
            setCustomDate(true)
            let dateSelected = moment(formik.values.pickupDay, 'MM-DD-YYYY').format('YYYY-MM-DD')
            let timeMinimum = moment(
                invoice_appointment.records?.[0]?.local_pickup_start_hour,
                'HH:mm a',
            ).format('HH:mm')
            let timeMaximum = moment(
                invoice_appointment.records?.[0]?.local_pickup_end_hour,
                'HH:mm a',
            ).format('HH:mm')
            timeMinimum = moment(dateSelected + ' ' + timeMinimum).format()
            timeMaximum = moment(dateSelected + ' ' + timeMaximum).format()
            const changeData = intervals(timeMinimum, timeMaximum)
            setAllAvailableTime(changeData)
        } else {
            setCustomDate(false)
            formik.values.pickupDay = ''
            setAllAvailableTime([{ value: '', show: `Select A Time` }])
        }
    }, [formik.values.pickupDay])

    useEffect(() => {
        if (responseStatusAppointment) {
            if (responseStatusAppointment.from === 'dashboard') {
                formik.setSubmitting(false)
                setIsLoading(false)
                if (responseStatusAppointment.status === 'success') {
                    handleRedirectInternal(history, 'invoices/all')
                }
            }
        }
    }, [responseStatusAppointment])

    return (
        <div className="row checkout justify-content-center">
            <div className="col-8 p-5">
                {isLoading ? (
                    <div className="fspLoader">
                        <Loaders isLoading={isLoading} />
                    </div>
                ) : (
                    <form onSubmit={formSubmit} autoComplete="nofill">
                        {formik.values.local_pickup_check &&
                        invoice_appointment.records?.[0]?.delivery_type === 'local_pickup' ? (
                            <div className="checkout">
                                <div className="schedule">
                                    <h4 className="checkoutHead">{'Select a Pickup Date' + ':'}</h4>
                                    <div className="chCnt d-flex justify-content-start align-items-center">
                                        <div className="selectDate d-flex justify-content-center align-items-center">
                                            <input
                                                name="pickupDay"
                                                checked={
                                                    formik?.values?.pickupDay >=
                                                        dateFormatFrontOnlyDate(today) &&
                                                    formik?.values?.pickupDay != ''
                                                        ? true
                                                        : false
                                                }
                                                type="radio"
                                                id={`schedule_1`}
                                                hidden
                                            ></input>
                                            <label>
                                                <ListItem
                                                    button
                                                    onClick={() => setDateCalendar(!dateCalendar)}
                                                >
                                                    {customDate ? (
                                                        <p>
                                                            {formik?.values?.pickupDay >=
                                                            dateFormatFrontOnlyDate(today)
                                                                ? formik.values.pickupDay
                                                                : dateFormatFrontOnlyDate(today)}
                                                        </p>
                                                    ) : (
                                                        <>
                                                            <p>Choose Date</p>
                                                            <span>({'Custom Date'})</span>
                                                        </>
                                                    )}
                                                </ListItem>
                                            </label>

                                            {mapData([
                                                {
                                                    label: 'Custom Date',
                                                    placeholder: 'Appointment',
                                                    disablePast: true,
                                                    open: dateCalendar,
                                                    onClose: () => setDateCalendar(false),
                                                    class: 'col-md-4 col-sm-6 col-12 d-none',
                                                    type: 'dateWithCustomPicker',
                                                    name: 'pickupDay',
                                                    minDate: moment(today).format('YYYY-MM-DD'),
                                                    minDateMessage: `${'date should be greater than the current date'} - ${dateTimeFormatFunction(
                                                        tomorrow,
                                                    )}`,
                                                    shouldEnableDate: invoice_appointment.records
                                                        ?.at(0)
                                                        ?.local_pickup_dates?.split(','),
                                                    // .map(
                                                    //     (d) =>
                                                    //         new Date(parseInt(d))
                                                    //             .toISOString()
                                                    //             .split('T')[0],
                                                    // ),
                                                    showTodayButton: false,
                                                    formik: formik,
                                                },
                                            ])}
                                        </div>
                                    </div>

                                    <p className="scheduleError">
                                        {formik.errors.pickupDay &&
                                            formik.touched.pickupDay &&
                                            formik.errors.pickupDay}
                                    </p>

                                    {formik.values.pickupDay && allAvailableTime.length > 0 && (
                                        <>
                                            <h4 className="checkoutHead">
                                                {'Select A Pickup Time' + ':'}
                                            </h4>
                                            <div className="chCnt timeSlots d-flex justify-content-start align-items-center flex-wrap">
                                                {allAvailableTime
                                                    .filter((availableTimeUnit) =>
                                                        formik.values.pickupDay <=
                                                        moment().format('MM-DD-YYYY')
                                                            ? moment(
                                                                  availableTimeUnit.value,
                                                                  'HH:mm',
                                                              )
                                                                  .format('HH:mm')
                                                                  .valueOf() >=
                                                              moment().format('HH:mm').valueOf()
                                                            : availableTimeUnit.value,
                                                    )
                                                    .map((data, index) => {
                                                        let dateArr =
                                                            formik.values.pickupDay.split('-')
                                                        let slotLeft =
                                                            invoice_appointment.records?.[0]
                                                                ?.local_pickup_appt_per_slot -
                                                            all_appointments_auction.records?.filter(
                                                                (apt) =>
                                                                    apt.appointmentTime ==
                                                                        new Date(
                                                                            `${dateArr[2]}/${dateArr[0]}/${dateArr[1]} ${data.value}`,
                                                                        ).toISOString() &&
                                                                    apt.status != 'open',
                                                            ).length
                                                        return (
                                                            slotLeft > 0 && (
                                                                <div
                                                                    className="selectDate d-flex justify-content-center align-items-center"
                                                                    key={index}
                                                                >
                                                                    <input
                                                                        name="pickupTime"
                                                                        value={data.value}
                                                                        onChange={
                                                                            formik.handleChange
                                                                        }
                                                                        type="radio"
                                                                        id={`scheduleTime_${index}`}
                                                                        hidden
                                                                    ></input>
                                                                    <label
                                                                        htmlFor={`scheduleTime_${index}`}
                                                                    >
                                                                        <ListItem button>
                                                                            <p>{data.show}</p>
                                                                            <hr />
                                                                            <p>
                                                                                {slotLeft} slot(s)
                                                                                left
                                                                            </p>
                                                                        </ListItem>
                                                                    </label>
                                                                </div>
                                                            )
                                                        )
                                                    })}
                                            </div>
                                            <p className="scheduleError">
                                                {formik.errors.pickupTime}
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => {
                                    handleRedirectInternal(history, 'invoices/paid')
                                    window.scrollTo(0, 0)
                                }}
                            />
                            <PrimaryButton type="submit" label={'Update'} />
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}

export default AppointmentManage
