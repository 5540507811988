import React, { useContext, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import DashboardLayout from '../Dashboard/DashboardLayout'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import '../Dashboard/Dashboard.css'
import './invoice.css'
import CustomTable from '@/product/components/molecules/CustomTable'
import InvoiceContext from '@/product/context/invoice/invoiceContext'
import CartContext from '@/product/context/cart/cartContext'
import { useTranslation } from 'react-i18next'
import CustomSearch from '@/product/components/molecules/CustomSearch'
import ReportContext from '@/product/context/seller/report/reportContext'
import CustomDialog from '@/product/components/organisms/Dialog'
import InvoiceView from './view'
import { Button, ListItem } from '@material-ui/core'
import { useFormik } from 'formik'
import PaymentManage from './PaymentManage'
import { mapData } from '@/product/common/components'
import AuthContext from '@/product/context/auth/authContext'
// import RefundInvoice from '@/custom/components/organisms/RefundInvoice'
import * as Yup from 'yup'

const InvoiceSeller = (props) => {
    const reportContext = useContext(ReportContext)
    const invoiceContext = useContext(InvoiceContext)
    const cartContext = useContext(CartContext)
    const [isLoading, setIsLoading] = useState(true)
    const { getAllExportDownload } = reportContext
    const { t } = useTranslation()
    const {
        all_invoices,
        getAllInvoices,
        changeProductStatus,
        responseStatus: responseStatusInvoice,
    } = invoiceContext
    const { responseStatus: responseStatusCart } = cartContext
    const authContext = useContext(AuthContext)
    const { user, isAuthenticated, saveSearch } = authContext

    const [changeStatus, setChangeStatus] = React.useState(false)
    const [invoiceNotesStatus, setInvoiceNotesStatus] = React.useState(false)

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        sid: 0,
    })

    const [manageCredits, setManageCredits] = useState({
        popup: false,
        status: 'new',
        id: 0,
        sid: 0,
    })

    const [manageRefund, setManageRefund] = useState({
        popup: false,
        status: 'new',
        data: null,
    })

    const toggleFullScreenPopup = (popup, status, id, sid) => {
        if (popup) {
            setManage({ popup, status, id, sid })
        } else {
            setManage({ popup, status: 'new', id: 0, sid: 0 })
        }
    }

    const toggleCreditsPopup = (popup, status, id, sid) => {
        if (popup) {
            setManageCredits({ popup, status, id, sid })
        } else {
            setManageCredits({ popup, status: 'new', id: 0, sid: 0 })
        }
    }

    const toggleRefundPopup = (popup, status, data) => {
        if (popup) {
            setManageRefund({ popup, status, data })
        } else {
            setManageRefund({ popup, status: 'new', data: null })
        }
    }

    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const toggleInvoiceModal = () => {
        setInvoiceNotesStatus(!invoiceNotesStatus)
    }

    let action = props.match.params.action

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: action ? action.toString() : null,
                    type: 'in',
                    field: 'b.paid',
                },
                active: {
                    value: 2,
                    type: 'in',
                    field: 'b.active',
                },
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'b.common_invoice',
                },
                fromdate: {
                    value: '',
                    type: 'like',
                    field: 'b.paid_date',
                },
                todate: {
                    value: '',
                    type: 'like',
                    field: 'b.paid_date',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllInvoices(values)
        },
    })

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            project_id: '',
            auction_id: '',
            notes: '',
        },
        validationSchema: Yup.object({
            notes: Yup.string().when('action', {
                is: 'cancel',
                then: Yup.string().required('Required!'),
            }),
        }),
        onSubmit: (values) => {
            if (
                values.action === 'paid' ||
                values.action === 'draft' ||
                values.action === 'cancel' ||
                values.action === 'unpaid'
            ) {
                changeProductStatus(values)
            } else {
                assignAuctionLot(values)
            }
        },
    })

    const formikNotesSelection = useFormik({
        initialValues: {
            invoice_notes: '',
        },
        validationSchema: Yup.object({
            invoice_notes: Yup.string().required('Required'),
        }),
        onSubmit: (values) => {
            console.log(values, 'checkValues')
        },
    })

    useEffect(() => {
        if (all_invoices) {
            setTableBody(all_invoices.records.length ? all_invoices.records : [])
            setIsLoading(false)
        }
    }, [all_invoices])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.filters.action.value = action ? action.toString() : null
        formik.values.page = 1
        formik.values.filters.fromdate.value = ''
        formik.values.filters.todate.value = ''
        formik.values.filters.searchterm.value = ''
        formik.values.filters.user = {
            field: 'p.user_id',
            type: 'in',
            value: user ? user.as_employees_id : null,
        }
        if (action === 'all') {
            formik.values.filters.active.type = 'all'
        } else {
            formik.values.filters.active.type = action === 'cancel' ? 'in' : 'notin'
            formik.values.filters.active.value = 2
        }

        getAllInvoices(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [action, user])

    useEffect(() => {
        console.log('formikSelection.values', formikSelection.values)
    }, [formikSelection.values])

    const onClickView = (id, sid, type) => {
        toggleFullScreenPopup(true, type, id, sid)
    }

    const onEditNotes = () => {
        toggleInvoiceModal(true)
    }

    const onClickEdit = (id, secondaryID, type) => {
        toggleCreditsPopup(true, type, id, secondaryID)
    }

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.project_id = data
        if (action === 'paid' || action === 'draft' || action === 'cancel' || action === 'unpaid') {
            setChangeStatus(true)
        }
    }

    const getMultipleAction = () => {
        let actionPush = [
            {
                buttonType: 'visibility',
                clickType: 'view',
                onclick: onClickView,
                tooltipTitle: 'View Document',
            },
            // {
            //     buttonType: 'description',
            //     clickType: 'view',
            //     onclick: onEditNotes,
            //     tooltipTitle: 'Add invoice notes',
            // },
        ]
        // if (action === 'unpaid' || action === 'draft' || action == 'partial') {
        if (action === 'draft' || action == 'partial') {
            actionPush.push({
                buttonType: 'monetization_on',
                clickType: 'edit',
                onclick: onClickEdit,
                tooltipTitle: 'Make payment',
            })
        }
        // if (action === 'paid') {
        //     actionPush.push({
        //         buttonType: 'undo',
        //         clickType: 'edit',
        //         onclick: onClickRefundInvoice,
        //         tooltipTitle: 'Refund Invoice',
        //         clickID: 'common_invoice',
        //         secondaryClickID: 'user_id',
        //     })
        // }
        return actionPush
    }

    const onClickRefundInvoice = (id, secondaryID) => {
        toggleRefundPopup(true, 'view', {
            id,
            user_id: secondaryID,
            projects_shipping: 'shipEngine',
        })
    }

    const tableValues = [
        {
            field: 'common_invoice',
            type: '',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'total_items',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: t('items'),
        },
        // {
        //     field: 'total_invoice',
        //     type: 'price',
        //     sort: true,
        //     firstChild: false,
        //     disablePadding: false,
        //     label: t('amount'),
        // },
    ]
    // if (action === 'unpaid' || action === 'draft') {
    //     tableValues.push({
    //         field: 'paid_amount',
    //         type: 'price',
    //         firstChild: false,
    //         disablePadding: false,
    //         label: 'Paid Amount',
    //     })
    // }

    tableValues.push(
        {
            field: 'projects_auction',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'projects_auction',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Subasta',
                },
                false: {
                    type: 'text',
                    value: 'Directa',
                },
            },
            label: t('sale'),
        },
        {
            field: 'users_first_name,users_last_name',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: t('user_company'),
        },
        {
            field: 'users_email',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: t('email'),
        },
        //date_added
        {
            field: 'date_added',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            dateFormat: 'DD-MM-YYYY',
            label: t('created_date'),
        },
    )

    if (action === 'cancel') {
        tableValues.push({
            field: 'returned_date',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Cancel Date',
        })
        tableValues.push({
            field: 'emp_name',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Employee name',
        })
    }

    if (action === 'paid') {
        tableValues.push({
            field: 'paid_date',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: t('paid_date'),
        })
    }

    tableValues.push({
        field: 'action',
        type: 'button',
        clickID: 'common_invoice',
        secondaryClickID: 'user_id',
        sort: false,
        firstChild: false,
        disablePadding: false,
        label: t('action'),
        isMultiple: getMultipleAction(),
    })

    const tableActions = []

    if (action === 'paid') {
        tableActions.push({
            label: t('Move to Unpaid'),
            icon: <span className="material-icons">low_priority</span>,
            onclick: onSelectMultiProducts,
            type: 'unpaid',
        })
    } else if (action === 'unpaid' || action === 'draft') {
        tableActions.push({
            label: t('Move to Paid'),
            icon: <span className="material-icons">low_priority</span>,
            onclick: onSelectMultiProducts,
            type: 'paid',
        })
        tableActions.push({
            label: t('Cancel Invoice'),
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'cancel',
        })
    } else {
        tableActions.push(
            // {
            //     label: 'Move to Unpaid',
            //     icon: <span className="material-icons">low_priority</span>,
            //     onclick: onSelectMultiProducts,
            //     type: 'draft',
            // },
            {
                label: t('Move to Paid'),
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'paid',
            },
        )
    }

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: t('invoice_number'),
                placeholder: t('enter_invoice_number'),
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
            {
                label: t('created_from_date'),
                placeholder: t('date_ending_from'),
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'fromdate',
                filter: true,
            },
            {
                label: t('created_to_date'),
                placeholder: t('date_ending_to'),
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'todate',
                filter: true,
            },
        ],
    }

    const reasonForCancelInvoice = [
        {
            label: 'Reason *',
            name: 'notes',
            type: 'text',
            placeholder: 'Reason for cancellation',
            formik: formikSelection,
        },
    ]

    const invoiceNotes = [
        {
            label: 'Notes',
            name: 'invoice_notes',
            type: 'textarea',
            placeholder: 'Enter your invoice notes',
            formik: formikNotesSelection,
        },
    ]

    useEffect(() => {
        if (responseStatusInvoice) {
            if (
                responseStatusInvoice.status === 'success' &&
                responseStatusInvoice.from === 'productchange'
            ) {
                setChangeStatus(false)
                getAllInvoices(formik.values)
            }
        }
    }, [responseStatusInvoice])

    useEffect(() => {
        if (responseStatusCart) {
            if (responseStatusCart.status === 'success') {
                if (responseStatusCart.from === 'payCartWallet') {
                    toggleCreditsPopup(false)
                    getAllInvoices(formik.values)
                } else if (responseStatusCart.from === 'payCartOffline') {
                    toggleCreditsPopup(false)
                    getAllInvoices(formik.values)
                }
            }
        }
    }, [responseStatusCart])

    const closeFunction = () => {
        getAllInvoices(formik.values)
    }

    return (
        <DashboardLayout title={t('my_documents')}>
            <div className="dashboard invoice">
                <div className="auctionStatus">
                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoiceseller/all">
                            {t('All')}
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoiceseller/paid">
                            {t('paid')}
                        </NavLink>
                    </ListItem>

                    <ListItem button>
                        <NavLink activeClassName="active" to="/invoiceseller/partial">
                            Pendiente de pago
                            {/* {t('unpaid')} */}
                        </NavLink>
                    </ListItem>
                </div>
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="d-flex justify-content-end">
                    {tableBody.length ? (
                        <Button
                            id="invoice_report"
                            onClick={() =>
                                getAllExportDownload({
                                    action: 'export',
                                    order: 'asc',
                                    orderby: '',
                                    search: 'all',
                                    report: `${action}_invoice_management`,
                                    fields: [
                                        {
                                            name: 'ID',
                                            field: 'common_invoice',
                                            type: '',
                                        },
                                        {
                                            name: t('items'),
                                            field: 'sold_qty',
                                            type: '',
                                        },
                                        {
                                            name: t('user_company'),
                                            field: 'winnerCompany',
                                            type: 'capitalize',
                                        },
                                        {
                                            name: t('name'),
                                            field: 'winnerName',
                                            type: 'capitalize',
                                        },
                                        {
                                            name: t('email'),
                                            field: 'winnerEmail',
                                            type: 'capitalize',
                                        },
                                        {
                                            name: t('created_date'),
                                            field: 'date_added',
                                            type: 'date',
                                        },
                                        {
                                            name: t('price'),
                                            field: 'buynowamount',
                                            type: 'price',
                                        },
                                    ],
                                    isDefault: false,
                                })
                            }
                        >
                            <span className="material-icons">get_app</span>
                            {t('export_all_data')}
                        </Button>
                    ) : null}
                </div>
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_invoices}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllInvoices}
                />
            </div>
            <InvoiceView
                data={manage}
                function={toggleFullScreenPopup}
                closeFunction={closeFunction}
            />
            <PaymentManage data={manageCredits} function={toggleCreditsPopup} />
            {/* <RefundInvoice data={manageRefund} function={toggleRefundPopup} /> */}
            <CustomDialog
                title={t('Change Invoice Status')}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>{t('Are you sure you want to change the status')}</h5>
                {formikSelection.values.action === 'cancel' && mapData(reasonForCancelInvoice)}
                <div className="actionWrapper">
                    <Button id="invoice_cancel" onClick={() => changeChangeStatus()}>
                        {t('cancel')}
                    </Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton id="invoice_confirm" type="submit" label={t('confirm')} />
                    </form>
                </div>
            </CustomDialog>

            <CustomDialog
                title="Invoice notes"
                open={invoiceNotesStatus}
                className="invoiceNotes"
                function={toggleInvoiceModal}
            >
                <h5>Add any specific information related to invoices here.</h5>
                {mapData(invoiceNotes)}
                <div className="actionWrapper">
                    <Button onClick={() => toggleInvoiceModal()}>Cancel</Button>
                    <form onSubmit={formikNotesSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </DashboardLayout>
    )
}

export default InvoiceSeller
