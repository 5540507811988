import React, { useState, useContext, useEffect } from 'react'

import AuthContext from '../../../../product/context/auth/authContext'
import CustomCommonContext from '../../../context/common/commonContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import commonContext from '@/product/context/common/commonContext'

import UserContext from '../../../../product/context/user/userContext'
import AlertContext from '../../../../product/context/alert/alertContext'
import ProductContext from '../../../../product/context/product/productContext'
import InvoiceContext from '../../../../product/context/invoice/invoiceContext'
import AuctionContext from '../../../../product/context/auction/auctionContext'
import CartContext from '../../../../product/context/cart/cartContext'
import BuyerContext from '../../../../product/context/buyer/buyerContext'
import AutopltContext from '../../../../product/context/autopilot/autopltContext'
import PayContext from '../../../../product/context/payment/payContext'
import StripeCardContext from '../../../../product/context/stripe/card/cardContext'
import StripeCustomerContext from '../../../../product/context/stripe/customer/customerContext'
import StripeBankContext from '../../../../product/context/stripe/bank/bankContext'

import SellerProductContext from '../../../../product/context/seller/product/productContext'
import SellerAuctionContext from '../../../../product/context/seller/auction/auctionContext'

import AppointmentContext from '../../../../product/context/appointment/appointmentContext'

const HeadAlert = () => {
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const customCommonContext = useContext(CustomCommonContext)
    const productContext = useContext(ProductContext)
    const payContext = useContext(PayContext)
    const cartContext = useContext(CartContext)
    const userContext = useContext(UserContext)
    const autopltContext = useContext(AutopltContext)
    const stripeBankContext = useContext(StripeBankContext)
    const stripeCardContext = useContext(StripeCardContext)
    const stripeCustomerContext = useContext(StripeCustomerContext)
    const sellerProductContext = useContext(SellerProductContext)
    const sellerAuctionContext = useContext(SellerAuctionContext)
    const buyerContext = useContext(BuyerContext)

    const productCommonContext = useContext(ProductCommonContext)

    const { loadUser, callEnd } = authContext
    const { getGlobalVariable, configVariables, allUISettings, setSignupModal } =
        customCommonContext
    const { getAllCountries, getAllStates, getAllCities, loaderSet } = productCommonContext

    const { setAlert } = alertContext
    const { responseStatus: responseStatusAuth, clearResponse: clearResponseAuth } = authContext
    const { responseStatus: responseStatusBuyer, clearResponse: clearResponseBuyer } = buyerContext

    const {
        responseStatus: responseStatusStripeCustomer,
        clearResponse: clearResponseStripeCustomer,
    } = stripeCustomerContext

    const { responseStatus: responseStatusStripeBank, clearResponse: clearResponseStripeBank } =
        stripeBankContext

    const { responseStatus: responseStatusStripeCard, clearResponse: clearResponseStripeCard } =
        stripeCardContext

    const { responseStatus: responseStatusProduct, clearResponse: clearResponseProduct } =
        productContext

    const { responseStatus: responseStatusPayment, clearResponse: clearResponsePayment } =
        payContext

    const { responseStatus: responseStatusCart, clearResponse: clearResponseCart } = cartContext

    const {
        updateProfile,
        responseStatus: responseStatusUser,
        clearResponse: clearResponseUser,
    } = userContext

    const { responseStatus: responseStatusAutoPlt, clearResponse: clearResponseAutoPlt } =
        autopltContext

    const {
        responseStatus: responseStatusSellerAuction,
        clearResponse: clearResponseSellerAuction,
    } = sellerAuctionContext

    const {
        responseStatus: responseStatusSellerProduct,
        clearResponse: clearResponseSellerProduct,
    } = sellerProductContext

    useEffect(() => {
        if (responseStatusUser) {
            if (!responseStatusUser.noAlert) {
                setAlert(responseStatusUser.message, responseStatusUser.status)
            }
            clearResponseUser()
        }
        if (responseStatusProduct) {
            if (!responseStatusProduct.noAlert) {
                setAlert(responseStatusProduct.message, responseStatusProduct.status)
            }
            clearResponseProduct()
        }
        if (responseStatusAutoPlt) {
            if (!responseStatusAutoPlt.noAlert) {
                setAlert(responseStatusAutoPlt.message, responseStatusAutoPlt.status)
            }
            clearResponseAutoPlt()
        }
        if (responseStatusAuth) {
            if (responseStatusAuth.from === 'register') {
                if (responseStatusAuth.status === 'success') {
                    setAlert('Successfully registered!', responseStatusAuth.status)
                    setSignupModal(false)
                    clearResponseAuth()
                } else {
                    setAlert(responseStatusAuth.message, responseStatusAuth.status)
                    clearResponseAuth()
                }
            } else {
                if (!responseStatusAuth.noAlert) {
                    setAlert(responseStatusAuth.message, responseStatusAuth.status)
                }
                clearResponseAuth()
            }
        }
        if (responseStatusPayment) {
            if (!responseStatusPayment.noAlert) {
                setAlert(responseStatusPayment.message, responseStatusPayment.status)
            }
            clearResponsePayment()
        }
        if (responseStatusCart) {
            if (!responseStatusCart.noAlert) {
                setAlert(responseStatusCart.message, responseStatusCart.status)
            }
            clearResponseCart()
        }

        if (responseStatusStripeBank) {
            if (!responseStatusStripeBank.noAlert) {
                setAlert(responseStatusStripeBank.message, responseStatusStripeBank.status)
            }
            clearResponseStripeBank()
        }

        if (responseStatusStripeCustomer) {
            if (!responseStatusStripeCustomer.noAlert) {
                setAlert(responseStatusStripeCustomer.message, responseStatusStripeCustomer.status)
            }

            clearResponseStripeCustomer()
        }

        if (responseStatusStripeCard) {
            if (!responseStatusStripeCard.noAlert) {
                setAlert(responseStatusStripeCard.message, responseStatusStripeCard.status)
            }
            clearResponseStripeCard()
        }
        if (responseStatusSellerProduct) {
            if (!responseStatusSellerProduct.noAlert) {
                setAlert(responseStatusSellerProduct.message, responseStatusSellerProduct.status)
            }
            clearResponseSellerProduct()
        }
        if (responseStatusSellerAuction) {
            if (!responseStatusSellerAuction.noAlert) {
                setAlert(responseStatusSellerAuction.message, responseStatusSellerAuction.status)
            }
            clearResponseSellerAuction()
        }
        if (responseStatusBuyer) {
            if (!responseStatusBuyer.noAlert) {
                setAlert(responseStatusBuyer.message, responseStatusBuyer.status)
            }
            clearResponseBuyer()
        }
    }, [
        responseStatusUser,
        responseStatusProduct,
        responseStatusAutoPlt,
        responseStatusAuth,
        responseStatusPayment,
        responseStatusCart,
        responseStatusStripeBank,
        responseStatusStripeCustomer,
        responseStatusStripeCard,
        responseStatusSellerProduct,
        responseStatusSellerAuction,
        responseStatusBuyer,
    ])

    useEffect(() => {
        if (responseStatusPayment) {
            if (responseStatusPayment.from === 'payment') {
                if (responseStatusPayment.status === 'success') {
                    if (responseStatusPayment.data && responseStatusPayment.data.url) {
                        const newWindow = window.open(
                            responseStatusPayment.data.url,
                            '_self',
                            'noopener,noreferrer',
                        )
                        if (newWindow) newWindow.opener = null
                    }
                }
            }
        }
    }, [responseStatusPayment])

    useEffect(() => {
        if (responseStatusStripeCustomer) {
            if (responseStatusStripeCustomer.status === 'success') {
                if (responseStatusStripeCustomer.from == 'create') {
                    updateProfile({ stripe_id: responseStatusStripeCustomer.data.id }, true)
                }
            }
        }
    }, [responseStatusStripeCustomer])

    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'updateProfile' ||
                responseStatusUser.from === 'updatePreference' ||
                responseStatusUser.from === 'userAddress'
            ) {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                }
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        loaderSet(true)
        if (localStorage.token) {
            loadUser(localStorage.token)
        } else {
            loaderSet(false)
        }
    }, [])

    useEffect(() => {
        if (callEnd) loaderSet(false)
    }, [callEnd])

    return <></>
}
export default HeadAlert
