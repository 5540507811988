import React, { useEffect, useContext, useState, Suspense } from 'react'
import { Route, BrowserRouter as Router, withRouter, Switch } from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Loaders from '@/custom/components/molecules/Loaders'
import AppointmentManage from '../views/Dashboard/Appointment/AppointmentManage'
import Header from '@/custom/components/molecules/Header'
import Footer from '@/custom/components/molecules/Footer'
import SubscriptionPlan from '@/views/Static/MySubscriptionPlan'
import Subscription from '../views/Subscription/index_skyflow'
import Maintanence from '@/product/components/organisms/Maintanence'
import InvoiceSeller from '../views/InvoiceSeller'
import InitialFunction from '../product/views/InitialFunction'

const UserView = React.lazy(() => import('@/views/Seller/Organization/UserView'))
const SellerMyAuctions = React.lazy(() => import('@/views/Seller/Dashboard/AuctionLots/dynamic'))
const AuctionType = React.lazy(() => import('@/views/Seller/PostProject/AuctionType'))
const Bank = React.lazy(() => import('@/views/Seller/Dashboard/Bank'))
const SellerListing = React.lazy(() => import('@/views/Seller/Dashboard/Listing/dynamic'))
const BuynowSellerListing = React.lazy(() =>
    import('@/views/Seller/Dashboard/Listing/buynowdynamic'),
)
const Reports = React.lazy(() => import('@/views/Seller/Report'))
const MyCardDetails = React.lazy(() => import('@/views/Seller/Dashboard/MyCardDetails'))
const CreateLot = React.lazy(() => import('@/views/Seller/PostProject/CreateLot'))
const Home = React.lazy(() => import('@/views/Home/'))
const Login = React.lazy(() => import('@/views/Login/login'))
const ForgotPassword = React.lazy(() => import('@/views/ForgotPassword'))
const ResetPassword = React.lazy(() => import('@/views/ResetPassword'))
const Search = React.lazy(() => import('@/views/Search'))
const MyAuctions = React.lazy(() => import('@/views/Dashboard/MyAuctions'))
const Registration = React.lazy(() => import('@/views/Registration'))
const Profile = React.lazy(() => import('@/views/Dashboard/Profile/index'))
const ChangePassword = React.lazy(() => import('@/views/Dashboard/Profile/ChangePassword'))
const MyInvoices = React.lazy(() => import('@/views/Dashboard/MyInvoices'))
const MyDeposits = React.lazy(() => import('@/views/Dashboard/MyDeposits'))
const MyTransactions = React.lazy(() => import('@/views/Dashboard/MyTransactions'))
const Invoice = React.lazy(() => import('@/views/Invoice'))
const DepositInvoice = React.lazy(() => import('@/views/DepositInvoice'))
const LiveAuction = React.lazy(() => import('@/views/ProductView/LiveAuction'))
const ProductViewPage = React.lazy(() => import('@/views/ProductView'))
const ProductViewInvite = React.lazy(() => import('@/views/ProductView/ProductView_Invite'))
const Cart = React.lazy(() => import('@/views/Cart'))
const BuynowCart = React.lazy(() => import('@/views/BuyNowCart'))
const Checkout = React.lazy(() => import('@/views/Checkout'))
const MyWallet = React.lazy(() => import('@/views/Dashboard/MyWallet'))
const MySubscription = React.lazy(() => import('@/views/Seller/Dashboard/MySubscription'))
const ErrorPage = React.lazy(() => import('@/views/ErrorPage'))
const Auction = React.lazy(() => import('@/views/Auction'))
const VerifyEmail = React.lazy(() => import('@/views/VerifyEmail'))
const AuctionTemplates = React.lazy(() => import('@/views/Seller/Template/Auction/dynamic'))
const ProductTemplates = React.lazy(() => import('@/views/Seller/Template/Product/dynamic'))
const Notifications = React.lazy(() => import('@/views/Dashboard/Notifications'))
const ContactUs = React.lazy(() => import('@/views/ContactUs'))
const StaticPage = React.lazy(() => import('@/views/Static'))
const SavedSearch = React.lazy(() => import('@/views/Dashboard/SavedSearch'))
const RosoomResponse = React.lazy(() => import('@/views/Rosoom/Response'))
const MyCard = React.lazy(() => import('@/views/Dashboard/MySkyflowCard'))
// const MyCard = React.lazy(() => import('@/views/Dashboard/MyCard'))
const MyBank = React.lazy(() => import('@/views/Dashboard/MyBank'))
const Offers = React.lazy(() => import('../views/Dashboard/Offers'))
const MakeofferCart = React.lazy(() => import('../views/MakeOfferCart'))
const SwitchUser = React.lazy(() => import('@/views/SwitchUser'))
const MySubscriptionList = React.lazy(() => import('@/views/Seller/Dashboard/MySubscriptionList'))

const Message = React.lazy(() => import('@/views/Message'))

//Routes function===>All major routes are handled here
//Initial Route====>/login
const Routes = () => {
    /*  const [sta, useSta] = useState([])
    let data = {
        limit: 12,
        page: 1,
        orderby: 'p.id, desc',
        order: '',
        filters: {
            category: {
                value: [],
                type: 'array',
                field: 'it.categoryTypeId',
            },
            searchbar: {
                value: '',
                type: 'like',
                field: 'p.title,p.desc_proc',
            },
        },
    }
    let resetData = {
        limit: 12,
        page: 1,
        orderby: 'p.id, desc',
        order: '',
        filters: {
            category: {
                value: [],
                type: 'array',
                field: 'it.categoryTypeId',
            },
            searchbar: {
                value: '',
                type: 'like',
                field: 'p.title,p.desc_proc',
            },
        },
    }
    const getSearch = (e) => {
        console.log('search###', e)
        useSta(e)
    }
     ```<Search
                            showItems={true}
                            filters={true}
                            sortby={true}
                            limit={true}
                            search={{ data, resetData }}
                            getSearch={getSearch}
     />```
    useEffect(() => {
        console.log('@#$sta', sta)
    }, [sta])*/
    return (
        <>
            <Header />
            <InitialFunction />
            <Maintanence ipAddress={null} />
            <Suspense fallback={<Loaders name="home" isLoading={true} />}>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/maintanence" />
                    <Route exact path="/login" component={Home} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/reset_password/:token" component={ResetPassword} />
                    {/* <Route exact path="/registration">
                        <Home openPopup={'registration'} />
                    </Route> */}
                    <Route exact path="/search/:type" component={Search} />
                    <Route exact path="/buscar/:type" component={Search} />
                    <Route exact path="/switch" component={SwitchUser} />
                    {/* <Search showItems={true} filters={true} sortby={true} limit={true} />
                    </Route> */}
                    <Route exact path="/liveAuction/:aid" component={LiveAuction} />
                    <Route exact path="/productView/:pid" component={ProductViewPage} />
                    <Route exact path="/venta/:pid" component={ProductViewPage} />
                    <Route exact path="/subasta/:pid" component={ProductViewPage} />
                    <Route exact path="/auction" component={Auction} />
                    <Route exact path="/invite" component={ProductViewInvite} />
                    <Route exact path="/contactarnos" component={ContactUs} />
                    <Route exact path="/saved_search" component={SavedSearch} />
                    <Route exact path="/myoffers" component={MakeofferCart} />
                    <Route exact path="/verify_email/:token" component={VerifyEmail} />
                    <Route exact path="/alloffers" component={Offers} />
                    <PrivateRoute exact path="/invoiceseller/:action" component={InvoiceSeller} />
                    <PrivateRoute exact path="/buynowcart" component={BuynowCart} />
                    <PrivateRoute exact path="/checkout/:type/:cart_id" component={Checkout} />
                    <PrivateRoute exact path="/cart" component={Cart} />
                    <PrivateRoute exact path="/auctions/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/watchlist/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/purchases/:type" component={MyAuctions} />
                    <PrivateRoute exact path="/cuenta/perfil" component={Profile} />
                    <PrivateRoute exact path="/account/profile" component={Profile} />
                    <PrivateRoute exact path="/account/change" component={ChangePassword} />
                    <PrivateRoute exact path="/account/notifications" component={Notifications} />
                    {/* <PrivateRoute exact path="/company_profile" component={CompanyProfile} /> */}
                    <PrivateRoute exact path="/invoices/:action" component={MyInvoices} />
                    <PrivateRoute exact path="/transactions" component={MyTransactions} />
                    <PrivateRoute exact path="/invoice/:id" component={Invoice} />
                    <PrivateRoute exact path="/depositinvoice/:id" component={DepositInvoice} />
                    <PrivateRoute exact path="/deposits/:action" component={MyDeposits} />
                    <PrivateRoute exact path="/payments/saved_cards" component={MyCard} />
                    <PrivateRoute exact path="/payments/bank_details" component={MyBank} />
                    <PrivateRoute exact path="/wallet" component={MyWallet} />
                    <PrivateRoute exact path="/rosoom_success" component={RosoomResponse} />
                    <Route exact path="/seller/templates" component={AuctionTemplates} />
                    <Route
                        exact
                        path="/seller/payments/mysubcriptionlist"
                        component={MySubscriptionList}
                    />
                    <Route exact path="/seller/product/templates" component={ProductTemplates} />
                    <Route exact path="/payments/subscription" component={MySubscription} />
                    <PrivateRoute exact path="/documents/all" component={MyInvoices} />
                    <Route exact path="/seller/organization" component={UserView} />
                    <Route exact path="/appointment/schedule/:id" component={AppointmentManage} />
                    <Route exact path="/seller/reports" component={Reports} />
                    <Route
                        exact
                        path="/post_project/auction_detail/:action"
                        component={AuctionType}
                    />
                    <Route exact path="/post_project/create_lot/:action" component={CreateLot} />

                    <PrivateRoute
                        exact
                        path="/seller/auctions/:action"
                        component={SellerMyAuctions}
                    />

                    <PrivateRoute exact path="/seller/lots/:action" component={SellerListing} />
                    <PrivateRoute exact path="/seller/auction/:action" component={SellerListing} />
                    <PrivateRoute
                        exact
                        path="/seller/buynow/:action"
                        component={BuynowSellerListing}
                    />

                    <PrivateRoute exact path="/seller/payments/bank_details" component={Bank} />
                    <PrivateRoute
                        exact
                        path="/seller/payments/saved_cards"
                        component={MyCardDetails}
                    />
                    <PrivateRoute exact path="/messages" component={Message} />
                    <Route
                        exact
                        path={[
                            '/about',
                            '/quienes-somos',
                            '/services',
                            '/help',
                            '/guidelines',
                            '/privacy_policy',
                            '/terms_of_use',
                            '/preguntas-frequentes',
                            '/menciones-legales',
                            '/vender-vehiculo',
                            '/activar-alerta',
                            '/subastar-vehiculo',
                            '/condiciones-generales-especiales-contratacion',
                            '/politica-cookies',
                            '/aviso-legal',
                            '/politica-proteccion-datos',
                            '/politica-privacidad',
                        ]}
                        component={StaticPage}
                    />

                    <Route exact path="/payments/viewplans" component={Subscription} />
                    <Route exact path="/contrataciones" component={SubscriptionPlan} />

                    <Route exact path="*" component={ErrorPage} />
                </Switch>
                <Footer />
            </Suspense>
            <Suspense fallback={<Loaders name="home" />}></Suspense>
        </>
    )
}
export default withRouter(Routes)
