import { Button } from '@material-ui/core'
import React from 'react'
import './Dashboard.css'
import SideNav from './SideNav'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const DashboardLayout = (props) => {
    const history = useHistory()
    const { t } = useTranslation()
    return (
        <div className="dashboard">
            <div className="customContainer">
                <div className="d-flex justify-content-start">
                    <div className="dashboardLt">
                        <SideNav />
                    </div>
                    <div className="dashboardRt">
                        <div className="maTitle d-flex justify-content-between align-items-center w-100">
                            <h2 className="dashTitle">{props.title}</h2>
                            <Button
                                id="dashboard_back"
                                onClick={() => {
                                    history.goBack()
                                    window.scrollTo(0, 0)
                                }}
                                className="moveBack"
                            >
                                <span className="material-icons">arrow_back</span>
                                {t('back')}
                            </Button>
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardLayout
